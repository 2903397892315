import { USER_DATA_UPDATE, UPDATE_FORM_DATA } from "../../Redux.constants";
import { clearAllData, updateFormData, updateUserData } from "./helper";
import { INITIAL_STATE } from "./initialState";

export default function UserDetails(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_DATA_UPDATE:
      return updateUserData(state, action.payload);
    case UPDATE_FORM_DATA:
      return updateFormData(state, action.payload);
      case 'CLEAR_ALL_DATA':
        return clearAllData();
    default:
      return { ...state };
  }
}
