import React from "react";
import { styled } from "styled-components";

const GradientButton = ({ children, radius, width,active, ...prop }) => {
  return (
    <Container radius={radius} width={width} {...prop} active={active}>
      {children}
    </Container>
  );
};

export default GradientButton;
const Container = styled.div`
  /* // padding:10px 14px; */
  background: transparent linear-gradient(104deg, #00C0A5 0%, #12C08B 59%, #2DBF66 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: ${({ radius }) => radius};
  padding: 8px 18px;
  font-size: 14px;
  /* width:inherit; */
  height: 40px;
  width: ${({ width }) => (width ? width : "inherit")};
  /* height:${({ height }) => height}; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:${({active})=>active ? 'pointer' : 'not-allowed'} ;
  opacity:${({active})=>active ? 1 : 0.6};

  font-family: "poppins_semibold";
  @media only screen and (max-width: 480px) {
    padding: 5px 10px;
    width:100%;
  }
`;
