// import logo from "./logo.svg";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Header from "./components/includes/Header";
import "remixicon/fonts/remixicon.css";
import MainRouter from "./components/routers/router/MainRouter";
import { I18nextProvider } from "react-i18next";
import i18n from "./components/general/Languages";
import React, { useEffect, useState } from "react";
import RtlProvider from "./components/general/RtlProvider";
import { Alert, Snackbar } from "@mui/material";
import useStore from "./utils/ZuStore";

function App() {
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });
  const { vertical, horizontal } = state;
  const error = useStore((state)=>state.error)
  const [open,setOpen]=useState(false)
  useEffect(()=>{
    if (error){
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 3000);
    }
  },[error])
  return (
    <div className="App">
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <Header />
          <MainRouter />
        </I18nextProvider>
          {error && <Snackbar open={open} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert severity="error" sx={{ width: '100%' ,zIndex: 1500}}>
            Application could not be updated.
            </Alert>
          </Snackbar>}
      </BrowserRouter>
      
    </div>
  );
}

export default App;
