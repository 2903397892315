import { KEYS } from '../../../../../dataKeys';

export function loginStarted(state, payload) {
  return {
    ...state,
    login: {
      loading: true,
      error: false,
    },
    data:payload
  };
}

export function loginSuccess(state, payload) {
  localStorage.setItem(KEYS.ACCESS_TOKEN, payload.data.token);
  return {
    ...state,
    login: {
      loading: false,
      error: false,
      data:payload
    },
  };
}


export function loginFailed(state, payload) {
  return {
    ...state,
    login: {
      loading: false,
      error: payload
    }
  };
}

export function userUpdate(state,payload) {
  return {
    ...state,
    userData:{
      ...state.userData,
      currentRoute:payload.currentRoute,
      isService:payload.isService ? payload.isService : state.userData.isService,
      isSelect:payload.isSelect ? payload.isSelect : state.userData.isSelect,
      isPersonal:payload.isPersonal ? payload.isPersonal : state.userData.isPersonal,
      isOtpModal:payload.isOtpModal ? payload.isOtpModal : state.userData.isOtpModal
    }
  }

}
export function clearFormData(state) {
  return {
    ...state,
    formData:{
      ...state.formData,
      form_field:[],
      service_id:'',
      sub_service_id:''
    },
    userData:{
      ...state.userData,
      isPersonal:false,
      isSelect:false,
      isService:false
    }
  }
}
export function clearForm(state){
  return {
    ...state,
    formData:{
      ...state.formData,
      form_field:[],
      service_id:'',
      sub_service_id:''
    },
    userData:{
      ...state.userData,
      isPersonal:false,
      isSelect:false,
      isService:false
    }
  }
}
export function formUpdate(state,payload){
  return{
    ...state,
    formData:{
      ...state.formData,
      ...payload
    }

  }
}

export function changeLanguage(state,payload){
  return{
    ...state,
    language:payload.language
  }
}

export function setService(state,payload){
  return {
    ...state,
    userData:{
      ...state.userData,
      isService:true
    }
  }
}

export function LoginUser(state,payload){
  return {
    ...state,
    login:{
      ...state.login,
      isVerified:payload.isVerified,
      data:{
        access:payload.access,
        refresh:payload.refresh,
        phone:payload.phone,
        phone_code:payload.phone_code
      }
    }
  }
}
