import React from 'react'
import { useDispatch } from 'react-redux';
import { styled } from 'styled-components';
import { updateData } from '../Redux/Actions/Login';

const SubmitButton = ({children,center,...prop}) => {
  const dispatch = useDispatch()
  return (
    <Cover center={center} {...prop}>{children}</Cover>
  )
}

export default SubmitButton

const Cover = styled.div`
    width:150px;
    height:35px;
    background-image: linear-gradient(to right, #00C0A5, #12C08B, #2DBF66);
    color:#fff;
    display:flex;
    align-items:center;
    justify-content:center;
    margin:${({center})=>center ? 'o auto' : null};
    margin-top:20px;
    cursor:pointer;
    border-radius:8px;
    font-size:14px;
    font-family: 'poppins_semibold';
`;