import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import OtpInput from "react-otp-input";
import SubmitButton from "../../../../general/SubmitButton";
import { useDispatch } from "react-redux";
import { updateData } from "../../../../Redux/Actions/Login";
import FocusTrap from "focus-trap-react";

const OtpModal = ({ otpModal, setOtpModal }) => {
  const [otp, setOtp] = useState("");
  const [time, setTime] = useState(300);
  const dispatch = useDispatch();
  useEffect(() => {
    const countdown = setInterval(() => {
      if (time > 0) {
        setTime((prevTime) => prevTime - 1);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, []);
  const formatTime = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formattedMinutes}:${formattedSeconds}`;
  };
  const handleRoute = () => {
    setOtpModal(false);
    dispatch(updateData({ currentRoute: "Attachments", isPersonal: true }));
  };
  return (
    <FocusTrap>
      <Cover otpModal={otpModal}>
        <Container>
          <Top>
            <i class="ri-close-fill" onClick={() => setOtpModal(false)}></i>
          </Top>
          <TopContent>
            <h3>Enter PIN21</h3>
            <p>
              Please enter 6 digit code sent to
              <br /> +971 9654 433410
            </p>
          </TopContent>
          <OtpCover>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              //   renderSeparator={<span>-</span>}
              number={true}
              renderInput={(props) => <input {...props} />}
            />
          </OtpCover>
          <Timer>{formatTime()}</Timer>
          <BottomSect>
            <p>
              Didn’t receive the code? <span>Resend code</span>
            </p>
          </BottomSect>
          <SubmitButton>VERIFY OTP</SubmitButton>
        </Container>
      </Cover>
    </FocusTrap>
  );
};

export default OtpModal;

const Cover = styled.div`
  width: 100%;
  height: 100vh;
  background: #00000090;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({ otpModal }) => (otpModal ? "scale(1,1)" : "scale(0,0)")};
  transition: 0.4s ease;
`;
const Container = styled.div`
  width: 35%;
  //   height: 200px;
  background: #fff;
  padding: 40px;
  border-radius: 20px;
`;
const Top = styled.div`
  text-align: right;
  font-size: 24px;
  i {
    cursor: pointer;
  }
`;
const OtpCover = styled.div`
  //   background:red;
  display: flex;
  justify-content: center;
  div {
    gap: 15px;
  }
  input {
    width: 40px !important;
    height: 40px;
    background: #f4f4f4;
    border: none;
    outline: none;
  }
`;
const TopContent = styled.div`
  text-align: center;
  margin-bottom: 20px;
  h3 {
    font-size: 24px;
  }
  p {
    margin-top: 10px;
    font-size: 16px;
  }
`;
const Timer = styled.div`
  text-align: center;
  margin-top: 20px;
`;
const BottomSect = styled.div`
  text-align: center;
  margin-top: 15px;
  p {
    span {
      color: #00c0a5;
      font-weight: 600;
      cursor: pointer;
    }
  }
`;
