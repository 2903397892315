import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";

const ContactInfo = () => {
  const {t} = useTranslation()
  return (
    <Cover>
      <p>
        {t("1010")}
      </p>
      <ContactCover>
        <Contact>
          <a href="tel:+ 971 50 451 2311">
            <i class="ri-customer-service-fill"></i>+ 971 50 451 2311
          </a>
        </Contact>
        <Contact className="whatsapp">
          <a href="https://wa.me/+971504512311" target="_blank">
            <i class="ri-whatsapp-line"></i>+ 971 50 451 2311
          </a>
        </Contact>
      </ContactCover>
    </Cover>
  );
};

export default ContactInfo;
const Cover = styled.div`
  text-align: center;
  margin-top: 40px;
  padding-bottom:40px;
  p{
    @media only screen and (max-width: 480px) {
        font-size: 14px;
    }
  }
`;
const Contact = styled.div`
  width: 50%;
  // background:red;
  padding: 10px;
  font-family: 'poppins_medium';
  font-weight:600;
  a{
    @media only screen and (max-width: 420px) {
      font-size: 14px;
    }
  }
  &.whatsapp {
    a {
      color: #27bf6f;
        font-family: 'poppins_medium';
        @media only screen and (max-width: 420px) {
        font-size: 14px;
    }
    }
  }
  
`;
const ContactCover = styled.div`
  width: 30%;
  margin: 0 auto;
  display: flex;
  position:relative;
  &::after{
    content:"";
    position: absolute;
    top:5px ;
    left:50%;
    width:1px;
    height:80%;
    border-radius: 1px;
    background:#C6C6C6;
  }
  @media only screen and (max-width: 1080px) {
      width:50%;
    }
  @media only screen and (max-width: 764px) {
      width:60%;
    }
  @media only screen and (max-width: 640px) {
      width:70%;
    }
  @media only screen and (max-width: 540px) {
      width:80%;
    }
  @media only screen and (max-width: 480px) {
      width:90%;
    }
  @media only screen and (max-width: 420px) {
      width:100%;
    }
`;
