import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "styled-components";
// import { formUpdate } from "../../../Redux/Reducers/Login/Helper";
import { updateFormData } from "../../../Redux/Actions/Login";
import { useTranslation } from "react-i18next";
// import { updateFormData } from "../../../Redux/Actions";

const SlectBox = ({ content, setSelected, selected, id ,req_document}) => {
  const dispatch = useDispatch();
  const datas = useSelector((state) => state.Login.formData);
  const handleSelection = () => {
    setSelected(id);
    dispatch(updateFormData({ sub_service_id: id }));
  };

  useEffect(() => {
    if (datas.content) {
      setSelected(datas.content);
    }
    if(datas.sub_service_id === id){
      setSelected(id)
    }
  }, []);
  const { t } = useTranslation();
  return (
    <Cover
      onClick={() => handleSelection()}
      className={datas.sub_service_id === id ? "active" : null}
      dir={"left"}
    >
      <Left>
        <Outer className={datas.sub_service_id === id ? "active" : null}>
          {selected === id ? <i class="ri-check-line"></i> : null}
        </Outer>
      </Left>
      <Right>
        <HeadContent className={datas.sub_service_id === id? "active" : null}>
          <h5>{content}</h5>
          {datas.sub_service_id === id ? (
            <>
              <p>{t("1015")}</p>
              <ul>
                {req_document.map((item,index)=>(
                  <li>{index + 1 + "."}{item.required_attachment}{" "}{item.attachment_validation.map((item)=>(item + " "))}</li>
                ))}
                {/* <li>{t("1016")}</li>
                <li>{t("1017")}</li>
                <li> {t("1018")}</li> */}
              </ul>
            </>
          ) : null}
        </HeadContent>
      </Right>
    </Cover>
  );
};

export default SlectBox;

const Cover = styled.div`
  /* //   width: 48%; */
  padding: 10px;
  height: 65px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: 0.4s ease;
  /* overflow-y:scroll; */

  @media only screen and (max-width: 840px) {
    width: 100%;
    height: min-content;
  }
  &.active {
    border: 1px solid #27bf6f;
    transition: 0.8s ease;
    height: min-content;
    align-items: flex-start;
    background: #f7fcf9;
    box-shadow: 0px 11px 23px #00000019;

  }
`;
const Left = styled.div``;
const Outer = styled.div`
  width: 20px;
  height: 20px;
  border: 1.5px solid #cccccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    color: #fff;
    font-size: 11px;
  }
  &.active {
    background: #27bf6f;
    box-shadow: 0px 11px 23px #00000019;
    border: 1.5px solid #fff;

  }
`;
const Right = styled.div`
  h5 {
    margin: 0;
    padding: 0;
  }
`;
const HeadContent = styled.div`
p{
  margin-bottom:15px;
      
  @media only screen and (max-width: 840px) {
    font-size:13px;
  }
}
  &.active {
    h5 {
      // font-family: "poppins_medium";
      font-weight: 500;
      margin-bottom: 15px;
      line-height: 1.5;
      @media only screen and (max-width: 480px) {
        font-size: 12px;
        margin-bottom: 10px;
        line-height: 1.6rem;
      }
    }
    h6 {
      font-family: "poppins_medium";
      margin-bottom: 8px;
      @media only screen and (max-width: 480px) {
        font-size: 12px;
      }
    }
  }
  h5 {
    // font-family: "poppins_medium";
    font-weight: 500;
    font-size: 16px;
    line-height: 1.8rem;
    @media only screen and (max-width: 480px) {
      font-size: 12px;
      line-height:1.6rem;
    }
  }
  h6 {
    font-weight: 500;
    font-size: 14px;
    @media only screen and (max-width: 480px) {
      font-size: 12px;
    }
  }
  ul {
    li {
      color: #838383;
      font-size: 14px;
      font-family: "poppins_medium";
      margin-bottom: 10px;
      @media only screen and (max-width: 1080px) {
        font-size: 10px;
      }
    }
  }
`;
