export const languages = [
    {
        id: 1,
        name: "english",
        code: "En",
    },
    {
        id: 2,
        name: "french",
        code: "Fr",
    },
    {
        id: 3,
        name: "Hindi",
        code: "hnd",
    },
    {
        id: 4,
        name: "Tamil",
        code: "tml",
    },
    {
        id: 5,
        name: "Malayalam",
        code: "mal",
    },
    {
        id: 6,
        name: "Arabic",
        code: "ar",
    },
];

export const En = {
    1000: "HOME",
    1001: "ABOUT US",
    1002: "SERVICES",
    1003: "NEWS",
    1004: "CONTACT US",
    1005: "APPLY ONLINE",
    1006: "CONTINUE",
    1007: "Search Application type",
    1008: "NEXT",
    1009: "BACK",
    1010: "If you have any questions or inquiries, Our consultants will assist you.",
    1011: "Drag and drop a file upload",
    1012: "or",
    1013: "Browse file",
    1014: "SUBMIT REQUEST",
    1015: "Required Documents",
    1016: "1.Photo with white background (should not smile) if the age below 15.",
    1017: "2.Scan original passport full page (colored & clear).",
    1018: "3.Entry permit.",
    1019: "New Born / 1 Year",
    1020: "New Residency (First time visiting UAE) / 1 Year",
    1021: "New Residency (Previously had UAE Residence Visa) / 1 Year",
    1022: "Emirates ID Renewal / 1 Year"
};
export const Fr = {
    1000: "MAISON",
    1001: "À PROPOS DE NOUS",
    1002: "PRESTATIONS DE SERVICE",
    1003: "NOUVELLES",
    1004: "CONTACTEZ-NOUS",
    1005: "POSTULER EN LIGNE",
    1006: "CONTINUE",
    1007: "Search Application type",
    1008: "NEXT",
    1009: "BACK",
    1010: "If you have any questions or inquiries, Our consultants will assist you.",
    1011: "Drag and drop a file upload",
    1012: "or",
    1013: "Browse file",
    1014: "SUBMIT REQUEST",
    1015: "Required Documents",
    1016: "1. 1 Photo with white Background (Should not smile) if the age bellow 15.",
    1017: "2. Scan original passport full page (Colored & clear).",
    1018: "3. Entry Permit.",
    1019: "New Born / 1 Year",
    1020: "New Residency (First time visiting UAE) / 1 Year",
    1021: "New Residency (Previously had UAE Residence Visa) / 1 Year",
    1022: "Emirates ID Renewal / 1 Year"
};

export const Ar = {
    1000: "الرئيسية",
    1001: "معلومات عنا",
    1002: "خدمات",
    1003: "أخبار",
    1004: "اتصل بنا",
    1005: "يقدم طلب على الإنترنت",
    1006: "يكمل",
    1007: "نوع تطبيق البحث",
    1008: "التالي",
    1009: "خلف",
    1010: "إذا كان لديك أي أسئلة أو استفسارات ، فسيساعدك مستشارونا.",
    1011: "قم بسحب وإسقاط تحميل الملف",
    1012: "أو",
    1013: "ملف الاستعراض",
    1014: "تقديم الطلب",
    1015: "المستندات المطلوبة",
    1016: "1. صورة واحدة بخلفية بيضاء (لا يجب أن تبتسم) إذا كان العمر أقل من 15 سنة.",
    1017: "2. مسح الصفحة الكاملة لجواز السفر الأصلي (ملون وواضح).",
    1018: "3. تصريح الدخول.",
    1019: "مولود جديد / سنة",
    1020: "إقامة جديدة (أول زيارة لدولة الإمارات العربية المتحدة) / سنة واحدة",
    1021: "إقامة جديدة (تأشيرة إقامة الإمارات العربية المتحدة سابقًا) / سنة واحدة",
    1022: "إقامة جديدة (تأشيرة إقامة الإمارات العربية المتحدة سابقًا) / سنة واحدة"
};
