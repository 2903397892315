import {
  LOGIN_SUCCESS,
  LOGIN_STARTED,
  LOGIN_FAILED,
  USER_UPDATE,
  FORM_UPDATE,
  CHANGE_LANGUAGE,
  LOGIN_USER,
  CLEAR_FORM_DATA,
  CLEAR_DATAS
} from '../../Redux.constants';
import {
  loginSuccess,
  loginStarted,
  loginFailed,
  userUpdate,
  formUpdate,
  changeLanguage,
  LoginUser,
  clearFormData,
  clearForm
} from './Helper';
import { INITIAL_STATE } from './initialState';

export default function Login(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return loginSuccess(state, action.payload);
    case LOGIN_STARTED:
      return loginStarted(state, action.payload);
    case LOGIN_FAILED:
      return loginFailed(state, action.payload);
    case USER_UPDATE:
      return userUpdate(state,action.payload);
    case FORM_UPDATE:
      return formUpdate(state,action.payload)
    case CHANGE_LANGUAGE:
      return changeLanguage(state, action.payload)
    case LOGIN_USER:
      return LoginUser(state,action.payload)
    case CLEAR_DATAS:
      return clearForm(state)
    case CLEAR_FORM_DATA:
      return clearFormData(state)
    default:
      return { ...state };
  }
}
