import React from "react";
import { styled } from "styled-components";
import FormNumber from "./FormNumber";
import { Route, Routes } from "react-router-dom";
import SelectService from "./SelectService";
import ContactInfo from "./ContactInfo";
import FormFooter from "./includes/FormFooter";
import TypeSelection from "./TypeSelection";
import PersonalDetails from "./PersonalDetails";
import Attachments from "./Attachments";
import { useSelector } from "react-redux";
import ApplicationStatus from "./ApplicationStatus";

const ApplicationForm = () => {
  const userData = useSelector((state) => state.Login.userData);

  return (
    <>
    <Container>
      <div className="wrapper">
        <FormNumber />
        {userData.currentRoute === "Select Service" ? (
          <SelectService />
        ) : userData.currentRoute === "Type of Application" ? (
          <TypeSelection />
        ) : userData.currentRoute === "Personal Details" ? (
          <PersonalDetails />
        ) : userData.currentRoute === "Attachments" ? (
          <Attachments />
        ) : 
        null
        }
        {/* <Routes>
                <Route path="/service" element={<SelectService/>}/>
                <Route path='/type' element={<TypeSelection/>}/>
                <Route path="/personal" element={<PersonalDetails/>}/>
                <Route path='/attachments' element={<Attachments/>}/>
            </Routes> */}
        <ContactInfo />
      </div>
    </Container>
      <FormFooter />
      </>
  );
};

export default ApplicationForm;
const Container = styled.div`
  padding: 40px 0px 0px 0px;
  min-height:90vh;
`;
