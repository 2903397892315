import React, { useState } from 'react';
import { styled } from 'styled-components';

const DropDown = ({ type, placeholder, showError, label = "Emirate *", error = false, choices, errorData, ...props }) => {
  const [focused,setFocused]=useState(false)
  const handleFocus = (e) => {
    e.target.classList.add('focused');
    setFocused(true)
  };

  const handleBlur = (e) => {
    e.target.classList.remove('focused');
    setFocused(false)
  };
  return (
    <Cover className={focused && 'active'}>
      <select {...props} onFocus={(e)=>handleFocus(e)} onBlur={(e)=>handleBlur(e)}>
        <option className="grey-text" disabled selected> Select </option>
        {choices?.map((item) => (
          <option value={item} key={item}>{item}</option>
        ))}
      </select>
      <span>{label}</span>
      {showError}
    </Cover>
  );
}

export default DropDown;

const Cover = styled.div`
  width: 48%;
  height: 50px;
  border: 1px solid #9A9A9A;
  border-radius: 8px;
  padding: 10px 5px;
  position: relative;
  margin-top: 10px;
  &.active{
    border: 1px solid #000;
    span{
      color:#000;
    }
  }
  &.error {
    border: 1px solid red;
    span {
      color: red;
    }
  }
  p {
    font-size: 12px;
    margin-top: 9px;
    color: red;
  }
  @media only screen and (max-width: 640px) {
    width: 100%;
  }
  select {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 0px 5px;
    line-height: 1.8rem;
    font-size:14px;
    /* color:#838383; */
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    option {
      &.grey-text {
        color: red;
      }
    }
  }
  span {
    font-size: 12px;
    position: absolute;
    background: #fff;
    top: -9px;
    left: 10px;
    padding: 0px 5px;
    color: #838383;
    font-family: 'poppins_regular';
  }

  select.focused {
    /* Override the default CSS to display options when focused */
    color:#838383;
  }
`;
