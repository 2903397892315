import { INITIAL_STATE } from "../../Login/initialState";

export function updateUserData(state,payload){
    return {
        ...state,
        routeDetails:{
          ...state.routeDetails,
          currentRoute:payload.currentRoute,
          isService:payload.isService ? payload.isService : state.userData.isService,
          isSelect:payload.isSelect ? payload.isSelect : state.userData.isSelect,
          isPersonal:payload.isPersonal ? payload.isPersonal : state.userData.isPersonal,
        }
      }
}
export function updateFormData(state,payload){
  return {
    ...state,
    formDetails:payload
  }
}
export function clearAllData() {
  return INITIAL_STATE;
}