import React from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

const LanguageButton = ({languageCode,...props}) => {
  return (
    <Cover {...props}>{languageCode}<i class="ri-arrow-down-s-line"></i></Cover>
  )
}

export default LanguageButton

const Cover = styled.div`
    padding:8px 12px;
    background:#F4F4F4;
    border-radius:30px;
    display:flex;
    color:#000;
    font-size:14px;
    align-items:center;
    justify-content:center;
    /* width: 67px;
    height: 40px; */
    cursor:pointer;
    i{
        font-size:18px;
    }
`;