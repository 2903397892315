import {
  ArrowDropDown,
  ArrowDropDownCircleOutlined,
  ArrowDropDownCircleSharp,
  Style,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import ContactInfo from "./ContactInfo";
import FormFooter from "./includes/FormFooter";
import { useNavigate } from "react-router-dom";
import { user_applications } from "../../../api/auth";
import ButtonLoader from "../../../utils/loader/ButtonLoader";
import { MenuItem, Pagination, Select, Stack } from "@mui/material";
import { ErrorDoc } from "../../../assets/icons";

const Profile = () => {
  const [datas, setDatas] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [userName, setUserName] = useState();
  const [userPhone, setUserPhone] = useState();
  const [view, setView] = useState(5);
  const [pagination,setPagination]= useState({})
  const [currentData, setCurrentData] = useState([]);
  useEffect(() => {
    const startIdx = (pagination.current_page - 1) * pagination.per_page;
    const endIdx = Math.min(startIdx + pagination.per_page, pagination.total);
    const newData = Array.from({ length: endIdx - startIdx }, (_, index) => index + startIdx + 1);
    setCurrentData(newData);
}, [pagination]);

  useEffect(() => {
    setLoader(true);
    try{
      user_applications({view,currentPage}).then(function (res) {
        setDatas(res.data);
        setPagination(res.pagination)
        setLoader(false);
        setUserName(res.username);
        setUserPhone(res.phone);
        setTotalPages(Math.ceil(res.data.length / view));
      });
    }
    catch(error){
      window.location.href = "/";
    }
  }, [view,currentPage]);
  const navigate = useNavigate();
  const getPaginationString = () => {
    const start_index = (currentPage - 1) * view;
    const end_index = Math.min(start_index + view, datas.length);

    return `${start_index + 1}-${end_index} of ${pagination.total}`;
  };
  // let totalPages = Math.ceil(datas.length / view);
  // useEffect(()=>{
  //     totalPages = Math.ceil(datas.length / view);
  // },[view])
  const GapArrowIcon = ({ children }) => {
    return (
      <span>
        <ArrowContainer style={{ marginLeft: 5 }}>
          {children}
          <ArrowDropDown />
        </ArrowContainer>
      </span>
    );
  };

  return (
    <Cover>
      <div className="wrapper">
        <Top>
          <p>HOME/PROFILE</p>
        </Top>
        <ProfileDetails>
          <h4>Hi {userName},</h4>
          <h5>{userPhone}</h5>
          <p>My Applications</p>
        </ProfileDetails>
        <Container>
          {
            <InnerContainer>
              <CoverCard>
                <TopHead>
                  <ApplicationId>
                    <TextMuted>Application ID</TextMuted>
                  </ApplicationId>
                  <NameAppli>
                    <TextMuted>Applicant Name</TextMuted>
                  </NameAppli>
                  <Type>
                    <TextMuted>Type of Application</TextMuted>
                  </Type>
                  <Status>
                    <TextMuted>Status</TextMuted>
                  </Status>
                </TopHead>
                {datas?.length > 0 ? (
                  !loader ? (
                    datas.map((item, index) =>
                        datas.length >= 1 ? (
                          <Widgets
                            onClick={() =>
                              navigate(`/profile/${item.application_code}`)
                            }
                          >
                            <ApplicationId>
                              <TextDark>{item.application_code}</TextDark>
                            </ApplicationId>
                            <NameAppli>
                              <TextDark>{item.name}</TextDark>
                            </NameAppli>
                            <Type>
                              <TextDark>{item.sub_service}</TextDark>
                            </Type>
                            <StatusCover>
                              <StatusData status={item.colour_code}>
                                <TextDark>{item.application_status}</TextDark>
                              </StatusData>
                            </StatusCover>
                          </Widgets>
                        ) : (
                          <p>No applications found</p>
                        )
                      )
                  ) : (
                    <ButtonLoader />
                  )
                ) : (
                  <NoImageContainer>
                    <img src={ErrorDoc} />
                    <p>No applications found</p>
                  </NoImageContainer>
                )}
              </CoverCard>
            </InnerContainer>
          }
        </Container>
        <PageContainer>
          <div className="label">
            Show:{" "}
            <Select
              labelId="View"
              id="demo-simple-select-filled"
              value={view}
              onChange={(e) => {setView(e.target.value);setCurrentPage(1)}}
              // IconComponent={GapArrowIcon}
            >
              <MenuItem value={5} selected>
                5
              </MenuItem>
              {/* <MenuItem value={1} >1</MenuItem> */}
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
            </Select>
          </div>
          {/* <h4>{getPaginationString()}</h4> */}
          <h4>{`${currentData[0]}-${currentData[currentData.length-1]} of ${pagination.total}`}</h4>
          {/* <h4>{
          (currentPage - 1) * view}-{min(start_index + view, total_items)} of {datas?.length}</h4> */}
          <Stack spacing={2}>
            <Pagination
              count={pagination.total_pages}
              size="small"
              page={currentPage}
              onChange={(e, page) => setCurrentPage(page)}
            />
          </Stack>
        </PageContainer>
        <ContactInfo />
      </div>
      <FormFooter />
    </Cover>
  );
};

// export default Profile;

export default Profile;
const Cover = styled.div``;
const Top = styled.div`
  padding: 20px 0px;
  font-size: 10px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
`;
const ProfileDetails = styled.div`
  padding: 20px 0px;
  h4 {
    font-size: 36px;
    margin-bottom: 15px;
  }
  h5 {
    color: #9a9a9a;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 40px;
  }
  p {
    font-size: 20px;
  }
`;
const Container = styled.div`
  background: #f7fcf9;
  padding: 30px;
  border-radius: 30px;
  @media only screen and (max-width: 480px) {
    padding: 0;
  }
`;
const InnerContainer = styled.div`
  padding: 30px;
  background: #fff;
  border-radius: 16px;
  @media only screen and (max-width: 480px) {
    padding: 0;
  }
  @media only screen and (max-width: 980px) {
    overflow-y:scroll;
  }
`;
const TopHead = styled.div`
  padding: 20px 0px;
  display: flex;
  gap:8px;
  /* justify-content: space-between; */
  border-bottom: 1px solid #dedede;
`;
const Status = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-start;

  @media only screen and (max-width: 480px) {
    width:20%;
  }
`;
const Type = styled.div`
  width: 50%;
  /* @media only screen and (max-width: 480px) {
    width: 20%;
  } */
`;
const ApplicationId = styled.div`
  width: 15%;
  /* @media only screen and (max-width: 840px) {
    width: 21%;
  } */
  /* @media only screen and (max-width: 480px) {
    width: 12%;
  } */
`;
const TextMuted = styled.h5`
  color: #9a9a9a;
  font-size: 16px;
  font-weight: 400;
  @media only screen and (max-width: 480px) {
    font-size: 11px;
  }
`;
const Widgets = styled.div`
  padding: 20px 0px;
  display: flex;
  /* justify-content: space-between; */
  border-bottom: 1px solid #dedede;
  cursor: pointer;
  @media only screen and (max-width: 640px) {
    align-items: center;
  }
  @media only screen and (max-width: 480px) {
    align-items: center;
  }
`;
const TextDark = styled.h5`
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #000000;

  @media only screen and (max-width: 980px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 764px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 10px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 9px;
  }
`;
const StatusData = styled.h5`
  padding: 6px 12px;
  background: ${({ status }) => status};
  border-radius: 20px;
  text-align: left;
  display:flex;
  align-items:center;
  height:40px;
  color: ${({ status }) => (status === "PROCESSING" ? "#000" : "#fff")};
  /* float:right; */
  /* padding: 3px 10px; */
  text-wrap: no-wrap;
  @media only screen and (max-width: 564px) {
    padding: 5px;
  }
  @media only screen and (max-width: 480px) {
    padding: 5px;
    height:25px;
  }
`;
const StatusCover = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-start;


  @media only screen and (max-width: 480px) {
    width: 20%;
  }
`;
const PageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  align-items: center;
  div {
    &.label {
      font-size: 13px;
    }
  }
  h4 {
    font-size: 14px;
    color: #9a9a9a;
  }
`;
const ViewCount = styled.div`
  background: #f4f4f4;
  padding: 5px 12px;
  border-radius: 20px;
  select {
    border: none;
    outline: none;
  }
`;
const NoImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  padding-top: 15px;
  color: red;
  img {
    width: 200px;
    height: 200px;
  }
  p {
    font-size: 16px;
    color: red;
  }
`;
const ArrowContainer = styled.span``;
const NameAppli = styled.div`
  width: 15%;
`;
const CoverCard = styled.div`
  width:100%;
  @media only screen and (max-width: 480px) {
    width:450px;
  }
  @media only screen and (max-width: 980px) {
    width:750px;
  }
  @media only screen and (max-width: 480px) {
    width:980px;
  }
`;