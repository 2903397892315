import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "styled-components";
import InputBox from "../../../../general/InputBox";
import TextInput from "../../../../general/TextInput";
import PhoneInput from "../../../../general/PhoneInput";
import DropDown from "../../../../general/DropDown";
import { editApplication } from "../../../../../api/auth";
import { emailValidation } from "../../general/emailValidation";

const EditFormModal = ({
  datas,
  application_id,
  setEditModal,
  setChanged,
  setEditChanged,
}) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const dispatch = useDispatch();
  const [editDatas, setEditDatas] = useState(datas);
  const { isOtpVerified } = useSelector((state) => state.Login.formData);
  const dataState = useSelector((state) => state.Login);
  const [fieldErrors, setFieldErrors] = useState({});
  const isFormComplete = () => {
    let isComplete = true;

    editDatas.forEach((fieldData) => {
      const fieldName = Object.keys(fieldData)[0];
      const { value } = fieldData[fieldName];
      if (!value || value.toString().trim() === "") {
        isComplete = false;
      }
      if (fieldData[fieldName].field_type == "email") {
        if (!emailValidation(value)) {
          isComplete = false;
        }
      }
    });

    return isComplete;
  };
  const handleFormValidation = () => {
    let newFieldErrors = {};

    editDatas.forEach((fieldData) => {
      const fieldName = Object.keys(fieldData)[0];
      const { value, label } = fieldData[fieldName];

      if (!value || value.toString().trim() === "") {
        newFieldErrors[fieldName] = `${label} is required.`;
      } else if (
        fieldData[fieldName].field_type === "email" &&
        !emailValidation(value)
      ) {
        newFieldErrors[fieldName] = `Please enter a valid email address.`;
      } else {
        // No need to clear the error message here
        // newFieldErrors[fieldName] = '';
      }
    });

    setFieldErrors(newFieldErrors);
  };
  // const [error,setError]=useState();
  const handleSubmit = () => {
    if (!isFormComplete()) {
      handleFormValidation();
      setError(true);
    } else {
      setError(false);
      setButtonLoading(true);
      if (isOtpVerified) {
        //   formSubmission(
        //     dataState.formData.form_field,
        //     dataState.formData.form_id,
        //     dataState.formData.sub_service_id
        //   ).then((res) => {
        //     if (res.status === 1) {
        //       dispatch(
        //         updateFormData({
        //           application_id: res.application_id,
        //         })
        //       );
        //       dispatch(
        //         updateData({ isPersonal: true, currentRoute: "Attachments" })
        //       );
        //       setButtonLoading(false);
        //     }else{
        //       setButtonLoading(false)
        //       setError(error)
        //       setErrorMessage(res.message)
        //     }
        //   });
      } else {
        //   dispatch(
        //     updateFormData({
        //       form_field: formData,
        //     })
        //   );
        setButtonLoading(false);
        //   setPhonemodal(true);
      }
    }
  };
  const handleEditSubmit = () => {
    if (!isFormComplete()) {
      handleFormValidation();
    } else {
      editApplication(application_id, editDatas).then((res) => {
        if (res.status == 1) {
          setEditModal(false);
          // setChanged(true)
          setEditChanged((prev) => !prev);
          setTimeout(() => {
            setChanged(false);
          }, 3000);
        }
      });
    }
  };
  const handleInputChange = (fieldName, value, phoneNumb) => {
    // Create a copy of editDatas to modify
    const updatedEditDatas = editDatas.map((fieldData) => {
      const fieldKey = Object.keys(fieldData)[0];
      if (fieldKey === fieldName) {
        return {
          [fieldKey]: {
            ...fieldData[fieldKey],
            value: value,
          },
        };
      }
      return fieldData;
    });

    // Update the editDatas state with the modified data
    setEditDatas(updatedEditDatas);
  };

  return (
    <Cover>
      <Contain>
        <Top>
          <h3>Edit Details</h3>
          <CloseButton>
            <i
              class="ri-close-fill"
              onClick={() => setEditModal(false)}
            ></i>
          </CloseButton>
        </Top>
        <Bottom>
          {editDatas.map((fieldData) => {
            const fieldName = Object.keys(fieldData)[0];
            let showError = fieldErrors[fieldName];
            const { field_type, label, validations, value, choice, choices } =
              fieldData[fieldName];

            if (
              field_type === "text" &&
              !choice &&
              validations.phone_number != true
            ) {
              if (label === "Comments") {
                return (
                  <>
                    <TextInput
                      key={fieldName}
                      // type={field_type}
                      label={label}
                      onChange={(e) =>
                        handleInputChange(fieldName, e.target.value)
                      }
                      value={fieldData[fieldName].value}
                      showError={
                        showError && <ErrorLabel>{showError}</ErrorLabel>
                      }
                    />
                  </>
                );
              } else if (
                field_type === "text" &&
                validations.phone_number == "true"
              ) {
                return (
                  <PhoneInput
                    key={fieldName}
                    label={label}
                    onChange={(e) =>
                      handleInputChange(
                        fieldName,
                        e.target.value,
                        validations.phone_number
                      )
                    }
                    maxLength={15}
                    value={fieldData[fieldName].value}
                    type="number"
                    errorData={fieldData[fieldName].value}
                    // error={error}
                    showError={
                      showError && <ErrorLabel>{showError}</ErrorLabel>
                    }
                  />
                );
              } else if (
                (field_type === "text" && fieldName == "whatsapp_phone_code") ||
                fieldName == "sponsor_phone_code"
              ) {
                return null;
              } else {
                return (
                  <InputBox
                    key={fieldName}
                    type={field_type}
                    label={label}
                    error={error}
                    onChange={(e) =>
                      handleInputChange(fieldName, e.target.value)
                    }
                    value={fieldData[fieldName].value}
                    showError={
                      showError && <ErrorLabel>{showError}</ErrorLabel>
                    }
                  />
                );
              }
            } else if (
              field_type === "text" &&
              validations.phone_number == true &&
              !choice
            ) {
              return (
                <PhoneInput
                  key={fieldName}
                  label={label}
                  onChange={(e) =>
                    handleInputChange(
                      fieldName,
                      e.target.value,
                      validations.phone_number
                    )
                  }
                  maxLength={15}
                  value={fieldData[fieldName].value}
                  type="number"
                  errorData={fieldData[fieldName].value}
                  // error={error}
                  showError={showError && <ErrorLabel>{showError}</ErrorLabel>}
                />
              );
            } else if (choice.length > 1 || choices.length > 1) {
              return (
                <DropDown
                  key={fieldName}
                  label={label}
                  onChange={(e) => handleInputChange(fieldName, e.target.value)}
                  choices={choice}
                  value={fieldData[fieldName].value}
                  errorData={dataState[fieldName]}
                  // error={error}
                  showError={
                    error && showError && <ErrorLabel>{showError}</ErrorLabel>
                  }
                />
              );
            }
          })}
        </Bottom>
        <ButtonSection>
          <CancelButton onClick={() => setEditModal(false)}>
            CANCEL
          </CancelButton>
          <SubmitButton onClick={() => handleEditSubmit()}>SUBMIT</SubmitButton>
        </ButtonSection>
      </Contain>
    </Cover>
  );
};

export default EditFormModal;

const Cover = styled.div`
  height: 100vh;
  background: #00000080;
  /* opacity: 0.7; */
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
`;
const Contain = styled.div`
  width: 50%;
  height: 600px;
  background: #fff;
  border-radius: 20px;
  overflow: scroll;
  @media only screen and (max-width: 1080px) {
    width: 70%;
  }
  @media only screen and (max-width: 980px) {
    width: 70%;
  }
  @media only screen and (max-width: 840px) {
    width: 80%;
  }
  @media only screen and (max-width: 420px) {
    width: 90%;
  }
`;
const Top = styled.div`
  padding: 20px;
  position:relative;
  border-bottom: 1px solid #0000000f;
  h3 {
    text-align: center;
  }
`;
const Bottom = styled.div`
  padding: 20px;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  /* overflow:scroll; */
`;
const ErrorLabel = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 5px;
  position: absolute;
  bottom: -21px;
  left: 0;
`;
const ErrorSect = styled.div`
  color: red;
  font-size: 12px;
`;
const ButtonSection = styled.div`
  display: flex;
  padding: 30px 20px 40px 20px;
  gap: 15px;
`;
const CancelButton = styled.button`
  width: 118px;
  height: 50px;
  background: #e3f7ea 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00c0a5;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  border: none;
  outline: none;
  &:focus {
    outline: 1px solid #000;
  }
`;
const SubmitButton = styled.button`
  width: 114px;
  height: 50px;
  background: transparent
    linear-gradient(109deg, #00c0a5 0%, #12c08b 59%, #2dbf66 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 10px 15px #00000014;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  border: none;
  outline: none;
  &:focus {
    outline: 1px solid #000;
  }
`;
const CloseButton = styled.div`
  position:absolute;
  top:15px;
  right:20px;
  font-size:30px;
  cursor:pointer;
`