import React, { useRef, useEffect } from 'react';

function OutsideClickDetector({ children, onOutsideClick }) {
  const divRef = useRef();

  // Add a click event listener to the document
  useEffect(() => {
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        onOutsideClick(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onOutsideClick]);

  return <div ref={divRef}>{children}</div>;
}

export default OutsideClickDetector;
