import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import SlectBox from "./general/SlectBox";
import { GradientButton } from "../../general";
import { useDispatch, useSelector } from "react-redux";
import { updateData } from "../../Redux/Actions/Login";
import RtlProvider from "../../general/RtlProvider";
import { useTranslation } from "react-i18next";
import { getSubservice } from "../../../api/auth";
import ComponentLoader from "../../../utils/loader/ComponentLoader";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Link, Element } from 'react-scroll';

const TypeSelection = () => {
  const [search,setSearch] = useState('')
  const [error,setError]=useState(false)
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Login } = useSelector((state) => state);
  const targetRef = useRef(null);
  const handleRoute = () => {
    dispatch(
      updateData({
        currentRoute: "Personal Details",
        type: selected,
        isSelect: true,
      })
      );
    };
    const [suService, setSubService] = useState([]);
    const [selected, setSelected] = useState();
  useEffect(() => {
    getSubservice(Login.formData.service_id).then((res) => {
      if (search.length > 3){
        const newData = suService.filter(item=>item.description.toLowerCase().includes(search.toLowerCase()))
        setSubService(newData);
      }else{
        setSubService(res.data);
      }
    });
  }, [search]);
  const handleChange = (value) =>{
    if (search.length > 2){
      const newData = suService.filter(item=>item.description.toLowerCase().includes(search.toLowerCase()))
      setSubService(newData);
    }
  }
  useEffect(()=>{
    if (selected){
      dispatch(
        updateData({
          currentRoute: "Type of Application",
          type: selected,
          isSelect: true,
        })
      )
      setSelected(selected)
    }
  },[selected])
  const language = useSelector((state) => state.Login.language);
  const [scroll,setScroll]=useState(false)
  const [scrollPoint,setScrollPoint]=useState()
  const handleScroll = () => {
    const scrollY = window.scroll.y;
  };
  useEffect(() => {
    const handleScroll = () => {
      // Define the scroll point where you want to trigger your function.
      const scrollPoint = 300; 
      const scrollPoint2 = 3850; 
      setScrollPoint(window.scrollY)
      // Check if the user has scrolled to the specified scroll point.
      if (window.scrollY >= scrollPoint) {
        setScroll(true)
        // Call your function when the scroll point is reached.
        // myFunction();
      }else{
        setScroll(false)
      }
      if(window.scrollY >= scrollPoint2){
        setScroll(false)
      }
      
    };

    // Attach the scroll event listener to the window or a specific element.
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts.
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleSmooth = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end', // You can adjust this to 'center' or 'end' as needed
      });
    }
  }
  
  return (
    <Cover>
      <Container>
        <RtlProvider>
          <Top language={language}>
            <i class="ri-search-line"></i>
            <input type="text" placeholder={t("1007")} onChange={(e)=>setSearch(e.target.value)}/>
          </Top>
        </RtlProvider>
        <RtlProvider>
          <Bottom>
            {suService ?
              suService.map((item, index) => (
                <SlectBox
                  content={item.description}
                  setSelected={setSelected}
                  selected={selected}
                  id={item.id}
                  req_document={item.required_attachments}
                  // onClick={()=>dispatch(
                  //   updateData({
                  //     currentRoute: "Type of Application",
                  //     type: selected,
                  //     isSelect: true,
                  //   })
                  // )}
                />
              )) : 
              <ComponentLoader/>
              }
          </Bottom>
          <p className="error_message">{error ? "Please select one service to continue":null}</p>
        </RtlProvider>
        <ButtonSect ref={targetRef}>
          <GradientButton
            width= "214px"
            height="50px"
            radius="8px"
            active = {selected ? true : false}
            onClick={() =>{
              if (selected){
              dispatch(
                updateData({
                  currentRoute: "Personal Details",
                  type: selected,
                  isSelect: true,
                })
              )
              }else{
                setError(true)
                setTimeout(() => {
                  setError(false)
                }, 3000);
              }}
            }
          >
            {t("1006")}
            <i style={{ marginLeft: 10 }} class="ri-arrow-right-line"></i>
            
          </GradientButton>
        </ButtonSect>
      </Container>
      {scroll && <ScrollButton onClick={()=>handleSmooth()}>
        <UnfoldMoreIcon/>
      </ScrollButton>}
    </Cover>
  );
};

export default TypeSelection;

const Cover = styled.div`
  padding: 40px;
  background: #f7fcf9;
  border-radius: 40px;
  @media only screen and (max-width: 480px) {
    padding:0px;
  }
`;
const Container = styled.div`
  padding: 40px;
  background: #fff;
  box-shadow: 0px 3px 28px #0000000f;
  border-radius: 20px;
  @media only screen and (max-width: 480px) {
    padding:15px;
  }
  
`;
const Top = styled.div`
  width: 40%;
  margin: 0 auto;
  border: 1px solid #838383;
  height: 35px;
  border-radius: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;

  @media only screen and (max-width: 1080px) {
    width: 90%;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
  input {
    outline: none;
    border: none;
    width: 100%;
    font-family: ${({ language }) =>
      language === "ar" ? "Noto Sans" : "Poppins"};
  }
`;
const Bottom = styled.div`
  padding: 20px 20px;
  // display:flex;
  // flex-wrap:wrap;
  // justify-content:center;
  // gap:15px;
  max-height:calc(100vh - 400px);
  overflow-y:scroll;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  @media only screen and (max-width: 1080px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const ButtonSect = styled.div`
  padding: 20px 0px;
  @media only screen and (max-width: 480px) {
    display: flex;
    justify-content: center;
  }
`;
const Button = styled.div`
  width: 150px;
  background: #15afa5;
  height: 40px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const ScrollButton = styled.div`
  position:fixed;
  bottom:10px;
  left:49.4%;
  width:20px;
  height:40px;
  background:aqua;
  opacity:0.6;
  border-radius:10px;
  display:flex;
  align-items:center;
  justify-content:center;
`;
