import { Rowing } from "@mui/icons-material";
import {  Container } from "@mui/material";
import { Button } from "react-scroll";
import { styled } from "styled-components";

const Aboutus = () => {
  return (
    <Container>
        <Box>
      <h1  >About Us</h1>
      <p  style={mystyle}>
        Welcome to our Online Amer Center Services Website – Your Gateway to
        Swift and Convenient Solutions in Dubai At AmerOnline, we are committed
        to redefining customer service in the realm of administrative and
        governmental procedures in Dubai. Understanding the complexity and
        time-sensitive nature of these processes, we've launched our online Amer
        Center to bring the efficiency, convenience, and speed you deserve right
        to your fingertips.
      </p>

     
          <h3 style={mystyle2}>
            Our Vision : Streamlining Services for Your Ease
          </h3>
          <p style={mystyle}>
            Our Vision: Streamlining Services for Your Ease Our vision is to
            transform the way individuals and businesses interact with
            government services in Dubai. We believe in a future where every
            necessary formality is just a click away, saving you time and
            hassle.
          </p>
       
        <h3 style={mystyle2}>
            Our Mission : Delivering Convenience with Speed
          </h3>
          <p style={mystyle}>
            Our mission is simple: to provide a seamless online experience for
            all your Amer Center and eventually all of your government needs.
            Whether it's visa services, Emirates ID processing, or any other
            government-related procedure, we are here to expedite the process
            with utmost efficiency.
          </p>
       

      <h3 style={mystyle2}>Why Choose Us?</h3>
      <p style={mystyle3}>
        * Efficiency at its Best: With Staff members having more than 30 years
        of experience in Immigration & Government services, We understand that
        time is precious. Our online platform is designed to process your
        requests quickly and accurately.
      </p>
      <p style={mystyle3}>
   
        * User-Friendly Interface: Navigate through our services with ease,
        thanks to our intuitive and easy-to-use online platform.
      </p>
      <p style={mystyle3}>

        * Expert Assistance: Our team of experienced professionals is always
        ready to guide you through every step, ensuring a hassle-free
        experience.
      </p>
      <p style={mystyle3}>
      
        * Transparent and Trustworthy: We believe in building trust through
        transparency. With us, what you see is what you get – no hidden fees or
        complicated jargon.
      </p>
      <p style={mystyle3}>

        * Always Accessible: Access our services anytime, anywhere. We are here
        to serve you 24/7, making sure that your needs are met on your schedule.
      </p>
      <h3 style={mystyle4}>Your Satisfaction, Our Priority</h3>
      <p style={mystyle}>
        Your satisfaction is at the core of everything we do. We continuously
        strive to improve our services based on your feedback, ensuring that we
        always meet and exceed your expectations.
      </p>
      <h3 style={mystyle2}>Connect with Us</h3>
      <p style={mystyle}>For any inquiries or support, our dedicated team is just an email or phone call away. Connect with us and experience the ease of managing your administrative & Government related tasks like never before.</p>
      </Box>
    </Container>
  );
};
export default Aboutus;

// const Cover = styled.div`
//   padding: 50px 0px;
//   font-size: 20px;
//   border-bottom: 1px solid #eaeaea;
//   margin-bottom: 20px;
//   line-height: 2

//   p {
//     span {
//       cursor: pointer;
//     }
//   }
//   /* min-height:80vh; */
// `;
// const Card = styled.div`
//   display: flex;
//   font-size: 20px;
// `;
// const Div = styled.div`
//   font-size: 15px;
//   padding: 10px;
// `;
const Box = styled.div`
  padding: 50px
`;
const mystyle={
  lineHeight:"1.5",
  padding:"20px 0px",
  textAlign:"justify"
}
const mystyle2 = {
  paddingTop:"30px",
  textAlign:"justify"
}
const mystyle3 = {
  paddingTop:"20px",
  textAlign:"justify",
  lineHeight:"1.5"
}
const mystyle4 = {
  lineHeight:"1.5",
  paddingTop:"50px",
  textAlign:"justify"
}