import Lottie from 'react-lottie';
import CompLoader from '../../assets/loader/loader1.json'


const ComponentLoader = () =>{
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: CompLoader,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    return(
        <Lottie options={defaultOptions} height={200} width={200} />
    )
}
export default ComponentLoader