import {
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGIN_STARTED,
    USER_UPDATE,
    FORM_UPDATE,
    CHANGE_LANGUAGE,
    LOGIN_USER,
    CLEAR_FORM_DATA,
    CLEAR_DATAS
} from '../../Redux.constants';
// import { login } from '../../../api/login';

export function signIn(payload) {
    return async (dispatch) => {
        dispatch({
            type: LOGIN_STARTED,
            payload: payload,
        });
        try {
            // const data = await login(payload);
            dispatch({
                type: LOGIN_SUCCESS,
                payload: payload,
            });
        } catch (error) {
            dispatch({
                type: LOGIN_FAILED,
                payload: error,
            });
        }
    };
}
// export function updateData(payload){
//     return {
//         type:USER_UPDATE
//     }
// }
export const updateData = (payload) => {
    return {
      type: USER_UPDATE,
      payload: payload
    };
  };

export const changeLanguage = (payload) => {
    return {
        type:CHANGE_LANGUAGE,
        payload:payload
    }
}

export const updateFormData = (payload) => {
    return {
        type: FORM_UPDATE,
        payload:payload
    }
}

export const updateUserAccess = (payload) => {
    return {
        type:LOGIN_USER,
        payload:payload
    }
}

export const clearData = () => {
    return {
        type:CLEAR_FORM_DATA,
    }
}
export const clearForms = () => {
    return {
            type:CLEAR_DATAS,
        }
    }

// export const updateUserData = (payload) => {
//     return {
//         type : USER_UPDATE,
//         pa
//     }
// }