import React from "react";
import { styled } from "styled-components";
import { AmericanIcon, MasterIcon, VisaIcon } from "../../../../assets/icons";

const FormFooter = () => {
  return (
    <Cover>
      <div className="wrapper">
        <Left>
          <ImageContainer>
            <img src={MasterIcon}/>
            <img src={VisaIcon}/>
            <img src={AmericanIcon}/>
          </ImageContainer>
          <p>Copyright © Amer center All Rights Reserved.</p>
        </Left>
        <Right>
          <ul>
            <li>Privacy Policy</li>
            <li>Refund Policy</li>
            <li>Terms and Conditions</li>
            <li>Contact</li>
          </ul>
        </Right>
      </div>
    </Cover>
  );
};

export default FormFooter;
const Cover = styled.div`
  background: #f4f4f4;
  /* position:sticky; */
  width:100%;
  bottom:0;
  /* margin-top: 40px; */
  height: 60px;
    @media only screen and (max-width: 764px) {
        padding: 20px 0px;
      }
    @media only screen and (max-width: 480px) {
        height:78px;
      }
  div {
    &.wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media only screen and (max-width: 764px) {
        display: block;
      }
      
    }
  }
`;
const Left = styled.div`
  font-size:12px;
  display:flex;
  align-items:center;
  flex-wrap:wrap;
  gap:10px;
  p{
    line-height:1.5rem;
  }
  @media only screen and (max-width: 840px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 480px) {
      text-align: center;
      justify-content:center;
    }
`;
const Right = styled.div`
  @media only screen and (max-width: 840px) {
    font-size: 12px;
  }
  ul {
    display: flex;
    gap: 15px;
    @media only screen and (max-width: 840px) {
      gap: 5px;
    }
    @media only screen and (max-width: 480px) {
      text-align: center;
      justify-content: center;
    }
    li{
      font-size:12px;
      @media only screen and (max-width: 420px) {
          font-size: 10px;
    }
    }
  }
`;
const ImageContainer = styled.div`
display:flex;
align-items:center;
gap:10px;
  img {
    width:40px;
    object-fit:contain;
  }
  @media only screen and (max-width: 980px) {
    display:none;
  }
`;