import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { useParams } from "react-router-dom";
import { get_application_status } from "../../../api/auth";
import { convertUtcToLocal, formatDate } from "../../../utils/functions";
import EditFormModal from "./includes/modals/EditFormModal";
import { ProfileIcon } from "../../../assets/icons";

const ApplicationProcces = (id) => {
  const [datas,setDatas]=useState([])
  useEffect(() => {
    get_application_status(id.id).then(function (res) {
      setDatas(res.data);
    });
  }, []);
  return (
    <Cover>
      <h4>Application Status</h4>
      <VerticalTimeline
        lineColor={"#00C0A5"}
        layout={"1-column-left"}
        animate={true}
        verticalTimelineElementSpacing={10}
      >
        {datas?.map((item)=>(
        <VerticalTimelineElement
          position={"right"}
          iconClassName={"icon_round"}
          className={"container_timeline"}
        >
          <Widgets>
            <RightSect>
              <h4>{item.status}</h4>
              <ContainCover>
              <UserSect>
                <img src={ProfileIcon}/><p>{item?.changed_by}</p>
              </UserSect>
              <span>{convertUtcToLocal(item?.date_updated)}</span>
              </ContainCover>
            </RightSect>
          </Widgets>
        </VerticalTimelineElement>))}
      </VerticalTimeline>
      {/* <EditFormModal/> */}
    </Cover>
  );
};

export default ApplicationProcces;

const Cover = styled.div`
  padding: 0px 0px 0px 40px;
  @media only screen and (max-width: 764px) {
    padding: 0px;
  }
  h4 {
    font-size: 20px;
    margin-bottom: 30px;
    font-family: "poppins_medium";
    @media only screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
`;
const Container = styled.div`
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: #00c0a5;
    left: 15px;
    top: 5px;
    z-index: -1;
    @media only screen and (max-width: 480px) {
      left: 10px;
    }
  }
`;
const Widgets = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
  &.top {
    margin-bottom: 40px;
  }
`;
const LeftSpan = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #00c0a5;
  border: 3px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 480px) {
    width: 20px;
    height: 20px;
  }
  i {
    color: #fff;
    @media only screen and (max-width: 480px) {
      font-size: 12px;
    }
  }
`;
const ProcessStatus = styled.div`
  // padding:10px;
  width: 130px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "poppins_medium";
  border-radius: 20px;
  background: #fff186;
  @media only screen and (max-width: 480px) {
    width: inherit;
    height: inherit;
    padding: 5px;
    font-size: 13px;
  }
`;
const RightSect = styled.div`
  h4 {
    font-size: 18px;
    margin-bottom: 8px;
    font-family: "poppins_medium";
    white-space: nowrap;
    @media only screen and (max-width: 480px) {
      font-size: 12px;
      margin-bottom: 6px;
    }
  }
  span {
    color: #838383;
    font-size: 13px;
    font-family: "poppins_medium";
    @media only screen and (max-width: 480px) {
      font-size: 13px;
    }
  }
`;
const UserSect = styled.div`
display:flex;
gap:7px;
color:#838383;
align-items: baseline;
font-size:600;
p{
    font-size:13px;
    text-wrap: nowrap;
}
  img{
    width:10px;
    object-fit:contain;
  }
`;
const ContainCover = styled.div`
  display:flex;
  align-items: baseline;
  gap:15px;
  span{
    font-size:13px;
    display:inline;
    text-wrap: nowrap;
  }
  @media only screen and (max-width: 420px) {
      flex-wrap:wrap;
      gap:6px;

    }
`;