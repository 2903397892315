import React,{useContext} from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Authroute = ({children}) => {

    const {isOtpVerified} = useSelector((state) => state.Login.formData);
    return isOtpVerified ? children : <Navigate to='/' />;
};

export default Authroute;