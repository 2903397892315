import Lottie from 'react-lottie';
import CompLoader from '../../assets/loader/button_loader.json'


const ButtonLoader = () =>{
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: CompLoader,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    return(
        <Lottie options={defaultOptions} height={35} width={50} />
    )
}
export default ButtonLoader