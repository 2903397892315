import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from 'styled-components'
import { updateData } from '../Redux/Actions/Login'

const InputBoxLog = ({type="text" ,placeholder,label,field,emailError=true,name,error,showError,...prop}) => {
  return (
    <>
    <Cover>
        <input type={type} placeholder={placeholder} name={name} {...prop}/>
        <span>{label}</span>
        {showError}
    </Cover>
    </>
  )
}

export default InputBoxLog

const Cover = styled.div`
    width:100%;
    height:50px;
    border:1px solid #9A9A9A;
    border-radius:8px;
    padding:10px 6px;
    position:relative;
    margin-top:10px;
    &.error{
      border:1px solid red;
      span{
        color:red;
      }
    }
    p{
      font-size:12px;
      margin-top:15px;
      color:red;
    }
    @media only screen and (max-width: 640px) {
        width: 100%;
    }
    input{
        width:100%;
        height:100%;
        border:none;
        outline:none;
        padding:0px 5px;
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
    }
    span{
        font-size:12px;
        position:absolute;
        background:#fff;
        top:-9px;
        left:10px;
        padding:0px 5px;
        color:#838383;
        font-family:'poppins_regular';
        @media only screen and (max-width: 480px) {
          font-size: 10px;
        }
    }
`;