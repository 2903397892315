import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {Ar, En,Fr} from './LanguageConstants';

const resources = {
  en: {
    translation: En
  },
  fr: {
    translation: Fr
  },
  ar: {
    translation:Ar
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'En', // set the default language
    fallbackLng: 'en', // set the fallback language
    interpolation: {
      escapeValue: false // allows using HTML tags in translations
    }
  });

export default i18n;
