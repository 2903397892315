import React from "react";
import { styled } from "styled-components";

const TextInput = ({label,showError,...prop}) => {
  return (
    <>
    <Cover>
      <span>{label}</span>
      <textarea placeholder="Write here..." rows="4" cols="30" {...prop}/>
    {showError}
    </Cover>
    </>
  );
};

export default TextInput;

const Cover = styled.div`
  width: 100%;
/* //   height: 35px; */
  border: 1px solid #9a9a9a;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  margin-top: 10px;
  @media only screen and (max-width: 640px) {
    width: 100%;
    heigt: 80px;
  }
  textarea{
    width:100%;

    border:none;
    outline:none;
    line-height:1.5;
  }
  span {
    font-size: 12px;
    position: absolute;
    background: #fff;
    top: -9px;
    left: 10px;
    padding: 0px 5px;
    color: #838383;
    font-family:'poppins_regular';
    @media only screen and (max-width: 480px) {
          font-size: 10px;
        }
  }
`;
