import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import ApplicationDetail from "./ApplicationDetail";
import ContactInfo from "./ContactInfo";
import ApplicationProcces from "./ApplicationProcces";
import FormFooter from "./includes/FormFooter";
import { application_details } from "../../../api/auth";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GradientButton } from "../../general";
import ComponentLoader from "../../../utils/loader/ComponentLoader";
import { useDispatch } from "react-redux";
import { clearData, updateData } from "../../Redux/Actions";
import { Alert } from "@mui/material";

const ApplicationCoverDetail = () => {
  let { id } = useParams();
  const [loading,setLoading]=useState(false)
  const [datas,setDatas] = useState()
  const [editModal,setEditModal]=useState(false)
  const [changed,setChanged] = useState(false)
  const [editChanged,setEditChanged] = useState(false)

  useEffect(()=>{
    setLoading(true)
    application_details(id).then(function(res){
      if (res.Status == 1){
      setDatas(res.data);
      setLoading(false)
      }else{
        navigate('/profile/application-not-found')
      }
    })
  },[changed])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // console.log(datas.application_status_name);
  const handleHome = () => {
    navigate('/');
    dispatch(updateData({ currentRoute: "Select Service" ,isService: false,}));
    dispatch(clearData())
  }
  return (
    <Cover>
      {loading ? <ComponentLoader/>:<ContainerCover className="wrapper">
        <Top>
          <p><span onClick={(e)=>handleHome()}>HOME/</span><Link to='/profile'>PROFILE</Link>/{id}</p>
        </Top>
        {datas?.application_status_name == "Application Created" ? <SuccessBanner>
          <StatusContainer>
            <Round>
              <i class="ri-check-line"></i>
            </Round>
            <h3>Success!</h3>
            <h5>Your application has been successfully submitted.</h5>
            <p>Our consultants will contact you shortly.</p>
          </StatusContainer>
        </SuccessBanner> : datas?.payement_link && !datas?.payment_completed ?
         <Container>
                <Left>
                    <p>Total Payable Amount</p>
                    <h4>AED {datas?.amount}</h4>
                </Left>
                <GradientButton2  height="50px" radius="8px" href={`${datas?.payement_link}`} target="_blank" rel="noopener noreferrer">PAY NOW</GradientButton2>
            </Container>:null}
        <BottomSect>
          <ApplicationDetail datas={datas} changed={changed} setChanged={setChanged} setEditChanged={setEditChanged}/>
          {datas && <ApplicationProcces id={datas?.user_application_id}/>}
        </BottomSect>
        <ContactInfo />
      </ContainerCover>}
      <FormFooter />
      {/* <Alert severity="error">This is an error alert — check it out!</Alert> */}
    </Cover>
  );
};

export default ApplicationCoverDetail;

const Cover = styled.div``;
const Top = styled.div`
  padding: 20px 0px;
  font-size: 10px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
  p{
    span{
      cursor:pointer;
    }
  }
  /* min-height:80vh; */
`;
const SuccessBanner = styled.div`
  padding: 20px;
  background: #f7fcf9;
  border-radius: 40px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StatusContainer = styled.div`
  text-align: center;
  h3 {
    font-size: 36px;
    font-family: 'poppins_semibold';
  }
  h5 {
    font-size: 16px;
    font-family: 'poppins_regular';
    line-height: 2rem;
    @media only screen and (max-width: 540px) {
    font-size: 14px;
  }
  @media only screen and (max-width:420px){
        margin-bottom: 10px;
        margin-top: 10px;
  }
  }
  p {
    font-size: 16px;
    color: #27bf6f;
    font-family: 'poppins_medium';
    @media only screen and (max-width: 540px) {
    font-size: 14px;
  }
  }
`;
const Round = styled.div`
  width: 70px;
  height: 70px;
  background: #27bf6f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  i {
    font-size: 30px;
    color: #fff;
  }
`;
const BottomSect = styled.div`
  padding: 30px 0px 0px 0px;
  display: flex;
  border-bottom: 1px solid #eaeaea;
  @media only screen and (max-width: 764px) {
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 764px) {
    border-bottom: inherit;
  }
`;
const Container = styled.div`
    padding:40px;
    background:#F7FCF9;
    border-radius:30px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    @media only screen and (max-width: 480px) {
          padding:15px 30px;
        }
`;
const Left = styled.div`
    p{
        font-size:16px;
        @media only screen and (max-width: 540px) {
          font-size: 12px;
        }
    }
    h4{
        font-size:32px;
        line-height: 3rem;
        @media only screen and (max-width: 540px) {
           font-size: 26px;
        }
        @media only screen and (max-width: 480px) {
           font-size: 20px;
        }
    }
`;
const ContainerCover = styled.div`
  
`;
const GradientButton2 = styled.a`
  background: transparent linear-gradient(104deg, #00C0A5 0%, #12C08B 59%, #2DBF66 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: ${({ radius }) => radius};
  padding: 8px 18px;
  font-size: 14px;
  /* width:inherit; */
  height: 40px;
  width: ${({ width }) => (width ? width : "inherit")};
  /* height:${({ height }) => height}; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "poppins_semibold";
  @media only screen and (max-width: 480px) {
    padding: 5px 10px;
  }
`;