import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const RtlProvider = ({ children }) => {
  const [direction, setDirection] = useState("ltr");

  useEffect(() => {
    const raf = setInterval(() => {
      const newDirection = document.documentElement.dir;
      if (newDirection !== direction) {
        setDirection(newDirection);
      }
    }, 100);

    return () => {
      clearInterval(raf);
    };
  }, []);
  const language = useSelector(state=>state.Login.language)
  return (
    <div dir={language === "ar" ? "rtl" : ""}>
      {children}
    </div>
  );
};
export default RtlProvider