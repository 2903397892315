export const INITIAL_STATE = {
  login: {
    loading: false,
    error: false,
    data: {
      
    }
  },
  userData:{
    routeDetail:{
      isService:false,
      currentRoute:'Select Service'
    },
    isService:false,
    service:'',
    isSelect:false,
    currentRoute:'Select Service'
  },
  formData:{

  },
  language:""
};
