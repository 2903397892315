import React from 'react'
import { styled } from 'styled-components'

const GrayButton = ({children,width,height,isLogin,...props}) => {
  return (
    <Cover width={width} height={height} isLogin={isLogin} {...props}>{children}</Cover>
  )
}

export default GrayButton

const Cover = styled.button`
    padding:12px;
    border-radius:40px;
    background:#F4F4F4;
    border:none;
    outline:none;
    color:#000;
    gap:5px;
    font-size:14px;
    display:flex;
    align-items: ${({isLogin})=>isLogin ? 'center' : 'center'};
    font-weight:600;
    justify-content:center;
    cursor:pointer;
    position:relative;
    border-radius: ${({ radius }) => radius};
    font-size: 14px;
    height: 40px;
    width: ${({ width }) => (width ? width : "inherit")};
    justify-content: center;
    cursor: pointer;
    font-family: "poppins_semibold";
    display: flex;
    align-items: stretch;
    justify-content: center;
    img{
      width:12px;
      object-fit:contain;
    }
    @media only screen and (max-width: 480px) {
      /* padding: 5px 10px; */
      font-size:12px;
    }
`;