import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { GradientButton } from "../../general";
import ApplicationDetail from "./ApplicationDetail";
import ApplicationProcces from "./ApplicationProcces";
import ContactInfo from "./ContactInfo";
import FormFooter from "./includes/FormFooter";
import { useParams } from "react-router-dom";
import { application_details } from "../../../api/auth";

const PaymentDetails = () => {
  let { id } = useParams();
  const [datas,setDatas] = useState()
  useEffect(()=>{
    application_details(id).then(function(res){
      setDatas(res.data);
    })
  },[])
  return (
    <Cover>
      <div className="wrapper">
        <Top>
          <p>HOME/PROFILE/9912</p>
        </Top>
        <Bottom>
            <Container>
                <Left>
                    <p>Total Payable Amount</p>
                    <h4>AED 100.00</h4>
                </Left>
                <GradientButton width="152px" height="50px" radius="8px">PAY NOW</GradientButton>
            </Container>
            <ProfileApplication>
                <ApplicationDetail datas={datas}/>
                {/* <ApplicationProcces id={datas?.id}/> */}
            </ProfileApplication>
        </Bottom>
        <ContactInfo/>
      </div>
      <FormFooter/>
    </Cover>
  );
};

export default PaymentDetails;

const Cover = styled.div``;
const Top = styled.div`
  padding: 20px 0px;
  font-size: 10px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
`;
const Bottom = styled.div`
    padding:20px 0px;
`;
const Container = styled.div`
    padding:40px;
    background:#F7FCF9;
    border-radius:30px;
    display:flex;
    align-items:center;
    justify-content:space-between;
`;
const Left = styled.div`
    p{
        font-size:16px;
        @media only screen and (max-width: 540px) {
          font-size: 12px;
        }
    }
    h4{
        font-size:32px;
        @media only screen and (max-width: 540px) {
           font-size: 26px;
        }
        @media only screen and (max-width: 480px) {
           font-size: 20px;
        }
    }
`;
const ProfileApplication = styled.div`
    padding-top:30px;
    display:flex;
    border-bottom:1px solid #DBDBDB;
    @media only screen and (max-width: 840px) {
    flex-wrap: wrap;
    border-bottom:inherit;
  }
`;