import { styled } from "styled-components";

const PageNotFound = () => {
  return (
    <NotfoundContainer className="not-found">
      <Heading>404 Page Not Found</Heading>
      <Paragraph>The page you are looking for could not be found.</Paragraph>
      <Paragraph>Please try again or contact us for assistance.</Paragraph>
    </NotfoundContainer>
  );
};
export default PageNotFound
const NotfoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 87vh;
  background-color: #f4f4f4;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;
`;
