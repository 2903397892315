import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import PhoneInput from "../../../../general/PhoneInput";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateData,
  updateFormData,
  updateUserAccess,
} from "../../../../Redux/Actions";
import {
  formSubmission,
  searchUser,
  sendOtp,
  verifyOtp,
} from "../../../../../api/auth";
import axios from "axios";
import OtpPhoneInput from "../../../../general/OtpPhoneInput";
import FocusTrap from "focus-trap-react";
import InputBox from "../../../../general/InputBox";
import InputBoxLog from "../../../../general/InputBoxLog";

const PhoneNumberModal = ({
  setPhonemodal,
  phonemodal,
  setOtpModal,
  otpModal,
}) => {
  const [otp, setOtp] = useState();
  const [iserror, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [time, setTime] = useState(300);
  const [phoneCode, setPhoneCode] = useState("971");
  const [secondPhone, setSecondPhone] = useState();
  const dataState = useSelector((state) => state.Login);
  const { phone_code } = useSelector((state) => state.Login.login.data);
  useEffect(() => {
    const countdown = setInterval(() => {
      if (time > 0) {
        setTime((prevTime) => prevTime - 1);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [time]);
  const [isTimeOver, setIsTimeOver] = useState(false);

  const formatTime = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    if (time == 0) {
      setIsTimeOver(true);
    }

    return `${formattedMinutes}:${formattedSeconds}`;
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleModal = () => {
    setPhonemodal(false);
    dispatch(updateData({ isPersonal: true, currentRoute: "Attachments" }));
  };
  const [userPhone, setUserPhone] = useState("");
  const handleSubmit = () => {
    if (!otpModal) {
      if (userPhone.length > 5) {
        if (secondPhone == userPhone) {
          if (name) {
            setOtpModal(true);
          } else {
            setError(true);
            setErrorMessage("Please enter your name");
            setTimeout(() => {
              setError(false);
              setErrorMessage("");
            }, 3000);
          }
        } else {
          if (name.length > 3) {
            sendOtp(userPhone, country?.dial_code).then((res) => {
              if (res.status == 1) {
                setTime(300);
                setOtpModal(true);
                setError(false);
                setErrorMessage("");
              } else {
                setError(true);
                setErrorMessage(res.message);
              }
            });
          } else {
            setError(true);
            setErrorMessage("Please enter your name");
            setTimeout(() => {
              setError(false);
              setErrorMessage("");
            }, 3000);
          }
        }
      } else {
        setError(true);
        setErrorMessage("Please enter your mobile number");
        setTimeout(() => {
          setError(false);
          setErrorMessage("");
        }, 3000);
      }
    } else {
      // dispatch(updateFormData({isOtpVerified:true}))
      verifyOtp(userPhone, country?.dial_code, otp, name).then(async (res) => {
        if (res.status == 1) {
          await dispatch(
            updateUserAccess({
              access: res.access.access,
              refresh: res.access.refresh,
              isVerified: true,
              phone: res.phone_number,
              phone_code: res.phone_code,
            })
          );
          await dispatch(updateFormData({ isOtpVerified: true }));
          if (dataState) {
            // formSubmission(
            //   dataState.formData.form_field,
            //   dataState.formData.form_id,
            //   dataState.formData.sub_service_id
            // ).then((res) => {
            //   if (res.status === 1) {
            //     dispatch(
            //       updateFormData({
            //         application_id: res.application_id,
            //       })
            //     );
            //     dispatch(
            //       updateData({ isPersonal: true, currentRoute: "Attachments" })
            //     );
            //    setPhonemodal(false)
            //     // setButtonLoading(false);
            //   }else{
            //     // setButtonLoading(false)
            //     setError(true)
            //     setErrorMessage(res.message)
            //   }
            // }
            //   )
            axios
              .post(
                `https://api.ameronline.com/api/v1/application/user-application/${dataState.formData.form_id}/`,
                { formData: dataState.formData.form_field },
                {
                  headers: {
                    Authorization: `Bearer ${res.access.access}`,
                  },
                  params: {
                    sub_service_id: dataState.formData.sub_service_id,
                  },
                }
              )
              .then((response) => {
                if (response.data.status === 1) {
                  dispatch(
                    updateFormData({
                      application_id: response.data.application_id,
                    })
                  );
                  dispatch(
                    updateData({
                      isPersonal: true,
                      currentRoute: "Attachments",
                    })
                  );
                  setPhonemodal(false);
                  window.location.reload();
                  // setButtonLoading(false);
                  setError(false);
                  setErrorMessage("");
                } else {
                  // setButtonLoading(false)
                  setError(true);
                  setErrorMessage(res.message);
                }
              });
            setPhonemodal(false);
          } else {
            setPhonemodal(false);
            window.location.reload();
          }
        } else {
          setError(true);
          setErrorMessage(res.message);
          setTimeout(() => {
            setError(false);
            setErrorMessage("");
          }, 5000);
        }
      });
    }
  };
  const handleResend = () => {
    sendOtp(userPhone, country?.dial_code).then((res) => {
      if (res.status == 1) {
        setTime(300);
        setOtp();
        setIsTimeOver(false);
      } else {
        setError(true);
        setErrorMessage(res.message);
        setTimeout(() => {
          setError(false);
          setErrorMessage("");
        }, 3000);
      }
    });
  };
  const handleOtpChange = (newOtp) => {
    // Remove non-numeric characters from input
    if (newOtp != (/\D/g, "")) {
      setOtp(newOtp);
    }
    // const numericOtp = newOtp.replace(/\D/g, '');
  };
  const [country, setCountry] = useState({
    name: "Saudi Arabia",
    flag: "🇸🇦",
    code: "SA",
    dial_code: "+966",
  });
  const handlePhone = (e) => {
    // if (userPhone.length < 15){
    if (e.key === "Enter") {
    }
    const numbersOnly = e.target.value.replace(/[^0-9]/g, "");

    setUserPhone(numbersOnly);
    // searchUser(country?.idd?.root + country?.idd?.suffixes[0],userPhone).then((res)=>{
    //   if (res.status == 1){
    //     if (res.first_name){
    //       setName(res.first_name)
    //     }else{
    //       setName('')
    //     }
    //   }
    // })
    // }else{
    //   setUserPhone(e.target.value)
    // }
  };
  useEffect(() => {
    searchUser(country?.dial_code, userPhone).then((res) => {
      if (res.status === 1) {
        if (res.first_name) {
          setName(res.first_name);
        } else {
          setName("");
        }
      }
    });
  }, [userPhone]);

  const nameRef = useRef();
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
      // console.log(nameRef);
      // nameRef.current.focus();
    }
  };
  const [name, setName] = useState();
  const [editable, setEditable] = useState(true);
  return (
    <FocusTrap>
      <Cover phonemodal={phonemodal}>
        <Container>
          <Top>
            <i
              class="ri-close-fill"
              onClick={() => {
                setPhonemodal(false);
                setOtpModal(false);
              }}
            ></i>
          </Top>
          <Bottom>
            <h3>{otpModal ? "Enter PIN" : "Verify mobile number"}</h3>
            {otpModal ? (
              <p className="number">
                Please enter the 6 digit code sent to <br />
                {country?.dial_code} {userPhone}{" "}
                <span
                  className="change"
                  onClick={() => {
                    setOtpModal(false);
                    setSecondPhone(userPhone);
                    setOtp();
                  }}
                >
                  Change
                </span>
              </p>
            ) : (
              <p>Enter your mobile number to continue</p>
            )}
            {!otpModal ? (
              <OtpPhoneInput
                width="100%"
                onChange={
                  (e) => handlePhone(e)
                  //   {
                  //   if (userPhone.length < 15) {
                  //     const numbersOnly = e.target.value.replace(/[^0-9]/g, '');
                  //     setUserPhone(numbersOnly);
                  //   }
                  // }
                }
                value={userPhone}
                setCountry={setCountry}
                country={country}
                setPhoneCode={setPhoneCode}
              />
            ) : (
              <OtpCover>
                <OtpInput
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={6}
                  inputType="number"
                  number={true}
                  renderInput={(props, index) => (
                    <input
                      type="password"
                      autoFocus={index === 0}
                      {...props}
                      onKeyDown={(e) => handleKeyDown(e)}
                    />
                  )}
                />
              </OtpCover>
            )}
            {otpModal ? null : (
              // <Timer>{!isTimeOver ? formatTime() : null}</Timer>
              <p className="send">
                OTP will be sent to this number via whatsapp
              </p>
            )}
            {!otpModal && (
              <InputBoxLog
                label="Name"
                onChange={(e) => {
                  if (secondPhone == userPhone) {
                    // if(!name){
                    setName(e.target.value);
                    // }
                  } else {
                    setName(e.target.value);
                  }
                }}
                value={name}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            )}
            <p className="error_message">{iserror ? errorMessage : null}</p>
            {otpModal && (
              <BottomSect isTimeOver={isTimeOver}>
                <p>
                  Didn’t receive the code?{" "}
                  <span
                    onClick={() => {
                      if (isTimeOver) {
                        handleResend();
                      }
                    }}
                  >
                    {!isTimeOver ? formatTime() : "Resend code"}
                  </span>
                </p>
              </BottomSect>
            )}
            <SubmitButton onClick={() => handleSubmit()}>
              {otpModal ? "VERIFY OTP" : "GET OTP"}
            </SubmitButton>
            {!otpModal && (
              <p className="continue">
                By continuing you agree to the
                <br />
                <span>Terms and Conditions </span> and{" "}
                <span>Privacy Policy.</span>
              </p>
            )}
          </Bottom>
        </Container>
      </Cover>
    </FocusTrap>
  );
};

export default PhoneNumberModal;

const Cover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #00000090;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease;
  z-index: 1000;
`;
const Container = styled.div`
  width: 554px;
  background: #fff;

  /* height: 495px; */
  border-radius: 24px;
  padding: 30px;
  display: flex;
  align-items: center;
  position: relative;
  @media only screen and (max-width: 640px) {
    width: 90%;
  }
  @media only screen and (max-width: 480px) {
    padding: 43px 0px;
  }
`;
const Top = styled.div`
  text-align: right;
  font-size: 24px;
  position: absolute;
  top: 15px;
  right: 20px;
  i {
    cursor: pointer;
    font-size: 24px;
    color: #acacac;
  }
`;
const Bottom = styled.div`
  width: 85%;
  margin: 0 auto;

  text-align: center;
  h3 {
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
    font-size: 32px;
    @media only screen and (max-width: 480px) {
      font-size: 20px;
    }
  }
  p {
    font-size: 14px;
    margin-bottom: 25px;
    @media only screen and (max-width: 480px) {
      font-size: 12px;
    }
    span {
      &.change {
        color: #1c90d9;
        text-decoration: underline;
        cursor: pointer;
        font-size: 13px;
      }
    }
    &.number {
      width: 60%;
      margin: 0 auto;
      line-height: 1.9rem;
      @media only screen and (max-width: 480px) {
        width: 90%;
      }
    }
    &.send {
      font-size: 12px;
      text-align: left;
      margin-top: 15px;
      color: #838383;
    }
    &.continue {
      line-height: 2rem;
    }
    &.error_message {
      color: red;
      font-size: 14px;
      margin-top: 15px;
    }
    span {
      font-weight: 600;
    }
  }
`;
const SubmitButton = styled.button`
  width: 70%;
  height: 50px;
  background-image: linear-gradient(to right, #00c0a5, #12c08b, #2dbf66);
  border-radius: 8px;
  color: #fff;
  display: flex;
  outline: none;
  border: none;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 20px;
  &:focus {
    border: 1px solid #000;
  }
`;
const OtpCover = styled.div`
  //   background:red;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  div {
    gap: 15px;
  }
  input {
    width: 40px !important;
    height: 40px;
    background: #f4f4f4;
    border: none;
    outline: none;
    @media only screen and (max-width: 480px) {
      width: 30px !important;
      height: 30px;
    }
  }
`;
const Timer = styled.div`
  text-align: center;
  margin-top: 20px;
`;
const BottomSect = styled.div`
  text-align: center;
  margin-top: 15px;
  p {
    span {
      color: #00c0a5;
      font-weight: 600;
      cursor: ${({ isTimeOver }) => (!isTimeOver ? "not-allowed" : "pointer")};
    }
  }
`;
