import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { AmerLogo, ProfileIcon } from "../../assets/icons";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { GradientButton, LanguageButton } from "../general";
import GrayButton from "../general/GrayButton";
import {
  changeLanguage,
  clearData,
  signIn,
  updateData,
} from "../Redux/Actions/Login";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../Redux/Reducers/Userdetails/helper";
import { dataChanges } from "../Redux/Actions/User";
// import { useDispatch, useSelector } from 'react-redux'
// import language from '../context/reducers/languages'
import i18n, { useTranslation } from "react-i18next";
import { languages } from "../general/Languages/LanguageConstants";
import { AppBar } from "@mui/material";
import PhoneNumberModal from "../screens/applicationForm/includes/modals/PhoneNumberModal";
import OutsideClickDetector from "../../utils/OutsideClickDetector";

const Header = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [languageModal, setLanguageModal] = useState(false);
  const [phoneModal, setPhonemodal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const boxRef = useRef(null);
  const login = useSelector((state) => state.Login.login);
  const language = useSelector((state) => state.Login.language);
  const formData = useSelector((state) => state.Login.formData);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [languageCode, setLanguageCode] = useState("En");
  const handleLang = (item) => {
    i18n.changeLanguage(item.code);
    setLanguageCode(item.code);
    setLanguageModal(false);
    dispatch(changeLanguage({ language: item.code }));
  };
  const [menu, setMenu] = useState(false);
  const handleMenu = () => {
    setMenu((prev) => !prev);
  };
  const handleSmallMenu = () => {
    // if (menu) {
    //   setMenu(false);
    //   document.body.classList.remove("modal-open");
    // } else {
    //   setMenu(true);
    //   document.body.classList.add("modal-open");
    setMenu((prev) => !prev);
  };
  useEffect(() => {
    if (language) {
      setLanguageCode(language);
      i18n.changeLanguage(language);
    }
  }, []);
  const [profileModal, setProfileModal] = useState(false);
  const [smallProfile, setSmallProfile] = useState();
  const containerRef = useRef(null);
  const containerRef2 = useRef(null);
  const handleProfile = () => {
    if (login.data.access) {
      setProfileModal((prev) => !prev);
      // setMenu(false)
      // navigate('/profile')
    } else {
      setMenu(false);
      setPhonemodal(true);
    }
  };
  const handleSmallProfile = () => {
    if (login.data.access) {
      setSmallProfile((prev) => !prev);
      // setMenu(false)
      // navigate('/profile')
    } else {
      setMenu(false);
      setPhonemodal(true);
    }
  };
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setLanguageModal(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [languageModal]);
  const handleProfileNav = () => {
    navigate("/profile");
    setPhonemodal(false);
    setMenu(false);
  };
  //handle outside click
  useEffect(() => {
    function handleOutsideClick(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setProfileModal(false);
      }
    }

    if (profileModal) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [profileModal]);
  useEffect(() => {
    function handleOutsideClick(event) {
      if (
        containerRef2.current &&
        !containerRef2.current.contains(event.target)
      ) {
        setSmallProfile(false);
      }
    }

    if (smallProfile) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [smallProfile]);
  return (
    <>
      <Container>
        <AppBar
          sx={{
            backgroundColor: "white",
            height: "95px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 3px 15px #0000000d",
            color: "none",
          }}
        >
          <div className="wrapper">
            <Left>
              <Logo>
                <a href="#">
                  <img src={AmerLogo} alt="Amer" />
                </a>
              </Logo>
              <Menu>
                <li>
                  <LinkMenu
                    onClick={() => {
                      navigate("/");
                      dispatch(
                        updateData({
                          currentRoute: "Select Service",
                          isService: false,
                        })
                      );
                      dispatch(clearData());
                    }}
                  >
                    {t("1000")}
                  </LinkMenu>
                </li>
                <li>
                  <LinkMenu
                    to="#"
                    onClick={() => {
                      navigate("/aboutus");
                    }}
                  >
                    {t("1001")}
                  </LinkMenu>
                </li>
                <li>
                  <LinkMenu
                    onClick={() => {
                      navigate("/");
                      dispatch(updateData({ currentRoute: "Select Service" }));
                    }}
                  >
                    {t("1002")}
                  </LinkMenu>
                </li>
                <li>
                  <LinkMenu>{t("1003")}</LinkMenu>
                </li>
                <li>
                  <LinkMenu to="#">{t("1004")}</LinkMenu>
                </li>
              </Menu>
            </Left>
            <Right className="big_screen">
              <GradientButton
                radius="20px"
                active={true}
                onClick={() => {
                  navigate("/");
                  dispatch(
                    updateData({
                      currentRoute: "Select Service",
                      isService: false,
                    })
                  );
                  setMenu(false);
                  dispatch(clearData());
                }}
              >
                {t("1005")}
              </GradientButton>
              <GrayButton
                onClick={() => handleProfile()}
                width={login.data.access && "40px"}
                height={login.data.access && "40px"}
              >
                <img src={ProfileIcon} />
                {"  "}
                {login.data.access ? null : "LOG IN"}
                {profileModal && (
                  <ProfileModal ref={containerRef}>
                    <ul>
                      <li
                        onClick={() => {
                          handleProfileNav();
                        }}
                      >
                        Profile
                      </li>
                      <li onClick={() => handleLogout()}>Logout</li>
                    </ul>
                  </ProfileModal>
                )}
              </GrayButton>
              {/* <LanguageButton
                languageCode={languageCode}
                onClick={(event) =>{event.stopPropagation(); setLanguageModal((prev) => !prev)}}
              /> */}
            </Right>
            <Right className="small_screen">
              <GrayButton onClick={() => handleSmallProfile()}>
                <img src={ProfileIcon} />
                {"  "}
                {login.data.access ? null : "LOG IN"}
                {smallProfile && (
                  <ProfileModal ref={containerRef2}>
                    <ul>
                      <li
                        onClick={() => {
                          handleProfileNav();
                        }}
                      >
                        Profile
                      </li>
                      <li onClick={() => handleLogout()}>Logout</li>
                    </ul>
                  </ProfileModal>
                )}
              </GrayButton>
              <i class="ri-menu-line" onClick={() => handleSmallMenu()}></i>
            </Right>
            {/* <SelectBox languageModal={languageModal} ref={boxRef}>
              {languages.map((item) => (
                <LanguagCover onClick={() => handleLang(item)}>
                  {item.name}
                </LanguagCover>
              ))}
            </SelectBox> */}
          </div>
          <SmallScreenMenu menu={menu}>
            <ul>
              <li>
                <LinkMenu
                  to="#"
                  onClick={() => {
                    navigate("/");
                    dispatch(
                      updateData({
                        currentRoute: "Select Service",
                        isService: false,
                      })
                    );
                    setMenu(false);
                    dispatch(clearData());
                  }}
                >
                  {t("1000")}
                </LinkMenu>
              </li>
              <li>
                <LinkMenu to="#">{t("1001")}</LinkMenu>
              </li>
              <li>
                <LinkMenu to="#">{t("1002")}</LinkMenu>
              </li>
              <li>
                <LinkMenu to="#">{t("1003")}</LinkMenu>
              </li>
              <li>
                <LinkMenu to="#">{t("1004")}</LinkMenu>
              </li>
              <li>
                <GradientButton
                  active={true}
                  width="145px"
                  height="40px"
                  radius="20px"
                >
                  {t("1005")}
                </GradientButton>
              </li>
              <li>
                {/* <GrayButton onClick={() => handleProfile()}>
                  <img src={ProfileIcon}/>{login.data.access ? null : 'LOGIN'}
                  {profileModal && <ProfileModal className="small_screen" >
                  <ul>
                    <li onClick={()=>{handleProfileNav()}}>Profile</li>
                    <li onClick={()=>handleLogout()}>Logout</li>
                  </ul>
                </ProfileModal>}
                </GrayButton> */}
                {/* <LanguageButton
                  languageCode={languageCode}
                  onClick={() => setLanguageModal((prev) => !prev)}
                /> */}
              </li>
            </ul>
          </SmallScreenMenu>
        </AppBar>
      </Container>
      <Outlet />
      {phoneModal ? (
        <PhoneNumberModal
          phonemodal={phoneModal}
          setPhonemodal={setPhonemodal}
          setOtpModal={setOtpModal}
          otpModal={otpModal}
        />
      ) : null}
    </>
  );
};

export default Header;
const Container = styled.div`
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  //   box-shadow: 0px 3px 15px #0000000d;
  position: relative;
  div {
    &.wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;
const Left = styled.div`
  display: flex;
  align-items: center;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  color: #000;
  &.small_screen {
    /* display:flex; */
    i {
      font-size: 24px;
    }
    display: none;
    @media only screen and (max-width: 840px) {
      display: flex;
      gap: 15px;
    }
  }
  &.big_screen {
    @media only screen and (max-width: 840px) {
      display: none;
    }
  }
`;
const Logo = styled.h1`
  a {
    img {
      width: 63px;
      object-fit: contain;
      margin-right: 20px;
    }
  }
`;
const Menu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  @media only screen and (max-width: 840px) {
    display: none;
  }
`;
const LinkMenu = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #000;
  cursor: pointer;
`;
const SelectBox = styled.div`
  position: absolute;
  width: 250px;
  padding: 10px;
  max-height: 200px;
  background: #f7fcf9;
  /* background:'red'; */
  right: 70px;
  top: 70px;
  border-radius: 8px;
  color: #000;
  border: 1px solid #141414;
  overflow-y: scroll;
  transform: ${({ languageModal }) =>
    languageModal ? "scale(1,1)" : "scale(0,0)"};
  transform-origin: top right;
  transition: 0.4s ease;
`;
const LanguagCover = styled.div`
  padding: 5px 8px;
  cursor: pointer;
`;
const SmallScreenMenu = styled.div`
  position: absolute;
  left: 0;
  top: 97px;
  width: 100%;
  height: ${({ menu }) => (menu ? "100vh" : "0px")};
  overflow: hidden;
  transition: 0.4s ease;
  background: #f7fcf9;
  z-index: 10;
  ul {
    li {
      padding: 15px;
      display: flex;
      gap: 10px;
      // border-bottom:1px solid #CECECE;
    }
  }
`;
const ProfileModal = styled.div`
  position: absolute;
  width: 125px;
  /* height:60px; */
  background: #f4f4f4;
  bottom: -90px;
  border-radius: 8px;
  padding: 10px;
  z-index: 200;
  &.small_screen {
    left: 0px;
  }
  ul {
    li {
      /* text-align:center; */
      padding: 5px 8px;
      cursor: pointer;
    }
  }
`;
