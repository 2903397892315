import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { AmerLogo } from "../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateData, updateFormData } from "../../Redux/Actions/Login";
import { getServices } from "../../../api/auth";

const SelectService = () => {
  const [services, setService] = useState([]);
  useEffect(() => {
    getServices().then(function (res) {
      if (res.status == 1) {
        setService(res.data);
      }
    });
  }, []);
  const handleService = (item) => {
    dispatch(
      updateData({
        service: item.title,
        service_id: item.id,
        isService: true,
        currentRoute: "Type of Application",
      })
    );
    dispatch(
      updateFormData({
        service_id: item.id,
      })
    );
    dispatch(updateFormData({ service: item.name }));
  };
  const dispatch = useDispatch();
  const serviceTitle = useSelector((state) => state.Login.formData.service_id);
  return (
    <Cover>
      <ContainerCover>
        {services?.map((item, index) => (
          <Container
            onClick={() => handleService(item)}
            className={serviceTitle === item.id ? "active" : null}
            key={index}
          >
            <ImageContainer>
              <img src={item.icon} alt="icons" />
            </ImageContainer>
            <h5>{item.title}</h5>
          </Container>
        ))}
      </ContainerCover>
    </Cover>
  );
};

export default SelectService;
const Cover = styled.div`
  background: #f7fcf9;
  padding: 60px 0px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  gap: 15px;
`;
const Container = styled.div`
  background: #fff;
  width: 30%;
  cursor:pointer;
  @media only screen and (max-width: 980px) {
    width: 40%;
  }
  @media only screen and (max-width: 540px) {
    width: 90%;
  }
  height: 160px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  transition: 0.4s ease;
  box-shadow: 0px 3px 28px #0000000f;
  cursor: pointer;
  h5{
    font-size:16px;
    text-align:center;
    @media only screen and (max-width: 420px) {
      font-size: 12px;
    }
  }
  &.active {
    background-image: linear-gradient(to right, #00c0a5, #12c08b, #2dbf66);
    color: #fff;
    i {
      color: #fff;
      transition: 0.4s ease;
    }
  }
  &:hover {
    background-image: linear-gradient(to right, #00c0a5, #12c08b, #2dbf66);
    color: #fff;
    i {
      color: #fff;
      transition: 0.4s ease;
    }
  }
  /* img {
    width: 50px;
    object-fit: contain;
    margin-bottom: 15px;
  } */
  i {
    color: #00c0a5;
    font-size: 40px;
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 480px) {
    width: 45%;
    justify-content: center;
  }
`;
const ContainerCover = styled.div`
  width: 50%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  padding:"20px";
  @media only screen and (max-width: 980px) {
    width: 80%;
    justify-content: center;
  }
  @media only screen and (max-width: 540px) {
    width: 90%;
    justify-content: center;
  }
  @media only screen and (max-width: 480px) {
    width: 90%;
    justify-content: center;
  }
`;

const ImageContainer = styled.div`
  width:80px;
  height:80px;
  background:#fff;
  border-radius:50%;
  padding:20px;
  display:flex;
  align-items:center;
  justify-content:center;
  margin-bottom:10px;
  img{
    width:100%;
  }
`;