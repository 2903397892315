import React from 'react';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import {composeWithDevTools} from 'redux-devtools-extension';
import rootReducer from './components/Redux/Reducers';
import { loadState, saveState } from './localStorage';
const persistedState = loadState();
const composeEnhancer = composeWithDevTools({trace: true});
// const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancer(applyMiddleware(thunk, logger))
);
store.subscribe(() => {
    saveState({
      Login: store.getState().Login
    });
  });
const ReduxProvider = props => (
    <Provider store={store}>{props.children}</Provider>
);

export default ReduxProvider;