import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "styled-components";
import { updateData } from "../../Redux/Actions";
import { useTranslation } from "react-i18next";

const FormNumber = ({ number = 4 }) => {
  const userData = useSelector((state) => state.Login.userData);
  const formData = useSelector((state) => state.Login.formData);
  const dispatch = useDispatch();
  const handleBack = () => {
    if (userData.currentRoute === "Type of Application") {
      dispatch(updateData({ currentRoute: "Select Service" }));
    } else if (userData.currentRoute === "Personal Details") {
      dispatch(updateData({ currentRoute: "Type of Application" }));
    } else if (userData.currentRoute === "Attachments") {
      // dispatch(updateData({ currentRoute: "Personal Details" }));
    }
  };
  const handleNext = async () => {
    if (userData.currentRoute === "Select Service") {
      if (userData.isService) {
        dispatch(updateData({ currentRoute: "Type of Application" }));
      }
    } else if (userData.currentRoute === "Type of Application") {
      if (userData.isSelect) {
        dispatch(updateData({ currentRoute: "Personal Details" }));
      } else if (formData.content) {
        dispatch(
          updateData({
            currentRoute: "Personal Details",
            // type: selected,
            isSelect: true,
          })
        );
      }
    } else if (userData.currentRoute === "Personal Details") {
      if (userData.isPersonal) {
        dispatch(updateData({ currentRoute: "Attachments" }));
      }
    }
  };
  const { t } = useTranslation();
  return (
    <>
      <Cover>
        <Container current={userData.currentRoute}>
          <Number
            className={
              userData.isService
                ? "active"
                : userData.currentRoute === "Select Service"
                ? "active"
                : null
            }
          >
            <p>{userData.isService ? <i class="ri-check-fill"></i> : "1"}</p>
            <StepTitle dark={userData.currentRoute === "Select Service" ? true : false}>SELECT SERVICE</StepTitle>
          </Number>
          <Number
            className={
              userData.isSelect ||
              userData.currentRoute === "Type of Application"
                ? "active"
                : "none"
            }
          >
            <p>{userData.isSelect ? <i class="ri-check-fill"></i> : "2"}</p>
            <StepTitle dark={userData.currentRoute === "Type of Application" ? true : false}>TYPE OF APPLICATION</StepTitle>

          </Number>
          <Number
            className={
              userData.isPersonal ||
              userData.currentRoute === "Personal Details"
                ? "active"
                : "none"
            }
          >
            <p>{userData.isPersonal ? <i class="ri-check-fill"></i> : "3"}</p>
            <StepTitle dark={userData.currentRoute === "Personal Details" ? true : false}>PERSONAL DETAILS</StepTitle>
          </Number>
          <Number
            className={
              userData.currentRoute === "Attachments" ? "active" : "none"
            }
          >
            <p>4</p>
            <StepTitle dark={userData.currentRoute === "Attachments" ? true : false}>ATTACHMENTS</StepTitle>
          </Number>
        </Container>
        <BottomSect>
          <Arrows
            onClick={() => handleBack()}
            className={
              userData.currentRoute == "Select Service" ? null : userData.currentRoute == "Attachments" ? null : "active"
              // userData.currentRoute != "Select Service" || userData.currentRoute != "Attachments" ? null :  "next"
            }
          >
            <i class="ri-arrow-left-line"></i>
            {t("1009")}
          </Arrows>
          <Service>{userData.currentRoute}</Service>
          <Arrows
            onClick={() => handleNext()}
            className={
              (formData.content &&
                userData.currentRoute === "Type of Application") ||
              (userData.isSelect &&
                userData.currentRoute === "Type of Application")
                ? "next"
                : userData.currentRoute === "Personal Details" &&
                  userData.isPersonal
                ? "next"
                : null
            }
          >
            {t("1008")} <i class="ri-arrow-right-line"></i>
          </Arrows>
        </BottomSect>
      </Cover>
    </>
  );
};

export default FormNumber;
const Cover = styled.div`
  width: 50%;
  margin: 0 auto;
  margin-bottom: 20px;
  /* position:fixed;
  width:100%; */
  @media only screen and (max-width: 720px) {
    width: 65%;
  }
  @media only screen and (max-width: 480px) {
    width: 80%;
  }
  @media only screen and (max-width: 480px) {
    width: 90%;
  }
`;
const Number = styled.div`
  width: 30px;
  height: 30px;
  background: #cecece;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  border: 3px solid #fff;
  font-size: 14px;
  position:relative;
  &.active {
    box-shadow: 0px 2px 5px #0000002F;
    background: #27bf6f;
  }
  p{
    line-height: 1;
    height: 10px;
  }
`;
const BottomSect = styled.div`
  margin-top: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Arrows = styled.div`
  color: #c7c7c7;
  cursor: pointer;
  font-size: 16px;
  font-weight:600;
  @media only screen and (max-width: 840px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 420px) {
    font-size: 12px;
  }
  &.active {
    color: #000000;
  }
  &.next {
    color: #27bf6f;
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background: #f2f2f2;
    left: 0;
    top: 45%;
    z-index: -2;
  }
  &::before {
    content: "";
    position: absolute;
    width: 35%;
    width: ${({ current }) =>
      current === "Select Service"
        ? "0"
        : current === "Type of Application"
        ? "35%"
        : current === "Personal Details"
        ? "65%"
        : current === "Attachments" && "100%"};
    height: 4px;
    transition: 0.4s ease;
    background-image: linear-gradient(to right, #00c0a5, #12c08b, #2dbf66);
    left: 0;
    top: 45%;
    z-index: -1;
  }
`;
const Service = styled.h3`
  font-size: 38px;
  text-transform: capitalise;
  font-family: "poppins_semibold";
  @media only screen and (max-width: 980px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 840px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 420px) {
    font-size: 18px;
  }
`;
const StepTitle = styled.div`
  position:absolute;
  color:${({dark})=>dark ? '#141414' : '#B5B5B5'};
  width: 120px;
  font-size:11px;
  text-wrap:no-wrap;
  font-weight:600;
  /* bottom:-25px; */
  top: 31px;
  white-space: nowrap;
  text-align:center;
  @media only screen and (max-width: 480px) {
    font-size: 8px;
  }
  @media only screen and (max-width: 420px) {
    font-size: 7px;
  }
  @media only screen and (max-width: 380px) {
    font-size: 7px;
  }
  @media only screen and (max-width: 320px) {
    font-size: 6px;
  }
  @media only screen and (max-width: 480px) {
    width: 100px;
  }
  
`;