export const INITIAL_STATE = {
    routeDetails:{
        isService:false,
        isSelect:false,
        isPersonal:false,
        currentRoute:'Select Service'
    },
    formDetails:{
        selectedService:"",
        selectedType: "",
        fullname:"",
        phone:"",
        sponsor:"",
        isOtpVerified:false
        
    }
}