export const INCREMENT ='INCREMENT';
export const DECREMENT ='DECREMENT';

export const INPUT_FORM_CHANGE ='INPUT_FORM_CHANGE';

export const LOGIN_SUCCESS ='LOGIN_SUCCESS';
export const LOGIN_STARTED ='LOGIN_STARTED';
export const LOGIN_FAILED ='LOGIN_FAILED';

export const USER_UPDATE = 'USER_UPDATE';
export const FORM_UPDATE = 'FORM_UPDATE';

export const VALIDATION_ERRORS ='VALIDATION_ERRORS';

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE"


export const USER_DATA_UPDATE = "USER_DATA_UPDATE"
export const UPDATE_FORM_DATA = "UPDATE_FORM_DATA"

export const LOGIN_USER = "LOGIN_USER"
export const CLEAR_FORM_DATA = "CLEAR_FORM_DATA"
export const CLEAR_DATAS = "CLEAR_DATAS"