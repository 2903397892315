// import { Navigate, json, useNavigate } from "react-router-dom";
// import { refreshToken } from "./auth";
// import { Alert, Snackbar } from "@mui/material";
// import { useErrorHandling } from "../utils/functions";
// import useStore from "../utils/ZuStore";
// import AlertHandle from "../components/includes/AlertHandle";
// import axios from "axios";

// const api = axios.create({
//   baseURL: "https://api.ameronline.com/api/v1/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// const getToken = () => {
//   const local = JSON.parse(localStorage.getItem("state"));
//   const token = local?.Login?.login?.data?.access;
//   return token;
// };

// if (getToken()) {
//   api.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
// }

// let isRefreshing = false;
// let failedRequestsQueue = [];
// api.interceptors.response.use(
//   (response) => {
//     const responseData = response.data;
//     return responseData;
//   },
//   async (error) => {
//     const originalRequest = error.config;
//     if (error.response) {
//       if (error.response.status === 401 && !originalRequest._retry) {
//         localStorage.clear();
//         originalRequest._retry = true;
//         if (!isRefreshing) {
//           isRefreshing = true;
//           try {
//             // Get the new access token
//             const newToken = await getToken();

//             // Set the new access token in the API interceptor
//             api.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;

//             // Retry the request with the new access token
//             return api(originalRequest);
//           } catch (refreshError) {
//             // Handle refresh token error
//             const navigate = useNavigate();
//             localStorage.clear();
//             window.location.reload()
//             navigate("/");
//           }
//         } else {
//           // Add the failed request to the queue
//           failedRequestsQueue.push(originalRequest);
//         }
//       }
//       if (error.response.status === 500) {
//         const navigate = useNavigate();
//         navigate("/");
//       }
//       if (error.response.status === 400) {
//         const errorTrue = useStore.getState().errorTrue;
//         errorTrue();
//       }
//       if (error.response.status === 404) {
//         // const navigate = useNavigate();
//         // navigate("/page-not-found");
//         // window.location.href = window.location.pathname + '/page-not-found'
//       }
//     } else if (error.request) {
//     } else {
//       // throw new ApiError('An unknown error occurred.');
//     }
//     if (error.code === "ECONNABORTED") {
//       // Handle timeout error here
//     }
//     return Promise.reject(error);
//   }
// );

// export default api;
import axios from "axios";

const instance = axios.create({
  // baseURL: `http://localhost:8000/api/v1/`,
  baseURL: `https://api.ameronline.com/api/v1/`,
  timeout: 50000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  let userInfo;
  const local = JSON.parse(localStorage.getItem("state"));
  const token = local?.Login?.login?.data?.access;
  if (localStorage.getItem("Login") && JSON.parse(localStorage.getItem("Login")).login.data.access) {
    userInfo = JSON.parse(localStorage.getItem("Login")).login;

  }
  let authToken;

  if (token) {
    authToken = token;
  }
  return {
    ...config,
    headers: {
      authorization: authToken ? `Bearer ${token}` : null,
    },
  };
});

// Add a response interceptor to handle 401 errors
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // Handle 401 Unauthorized error here
      console.log("Unauthorized! Logging out...");
      // Clear localStorage or perform logout logic
      localStorage.clear();
      // Redirect to the login page or any desired route
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

const responseBody = (response) => response.data;

const api = {
  get: (url, body, headers) =>
    instance.get(url, body, headers).then(responseBody),

  post: (url, body) => instance.post(url, body).then(responseBody),

  put: (url, body, headers) =>
    instance.put(url, body, headers).then(responseBody),

  patch: (url, body) => instance.patch(url, body).then(responseBody),

  delete: (url, body) => instance.delete(url, body).then(responseBody),
};

export default api;

