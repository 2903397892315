import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearData, updateData } from "../components/Redux/Actions";
import useStore from "./ZuStore";

export function formatDate(input) {
    // Convert the input to a Date object.
    // Note: Date expects year to be four digits
    const inputArr = input.split("-");
    const correctYear = (inputArr[2].split(" ")[0].length === 2) ? '20' + inputArr[2].split(" ")[0] : inputArr[2].split(" ")[0];
    const correctInput = inputArr[0] + "-" + inputArr[1] + "-" + correctYear;
    
    const date = new Date(correctInput);

    // Format the date using toLocaleString and return it.
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
    return date.toLocaleString('en-US', options);
}
export function truncateText(text, maxLength) {
    if (text?.length <= maxLength) {
      return text;
    } else {
      return text.slice(0, maxLength) + '...';
    }
  }

  export function convertUtcToLocal(utcDateTime) {
    const timeZone = 'Asia/Kolkata'; // Replace 'YourTimeZoneHere' with your actual time zone (e.g., 'America/New_York')
    const utcDateParts = utcDateTime.split(/[- :]/);
    const year = parseInt(utcDateParts[0]);
    const month = parseInt(utcDateParts[1]);
    const day = parseInt(utcDateParts[2]);
    const hour = parseInt(utcDateParts[3]);
    const minute = parseInt(utcDateParts[4]);
    const second = parseInt(utcDateParts[5]);
  
    const utcDate = new Date(Date.UTC(year, month - 1, day, hour, minute, second));
    const options = {
      day: 'numeric',   
      month: 'short',    
      year: 'numeric',   
      hour: '2-digit',   
      minute: '2-digit', 
      hour12: true,      
      timeZoneName: 'short',
    };
    const dateFormatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = dateFormatter.format(utcDate);
    const [formattedMonth, formattedDay, formattedYear, time, amPm] = formattedDate.split(' ');
    const formattedDayWithoutComma = formattedDay.replace(/,/g, '');
    const formattedTime = time.replace(/,/g, '') + ' ' + amPm;
    return `${formattedMonth} ${formattedDayWithoutComma} ${formattedYear} ${formattedTime}`;
  }
  
    
  export const HandleClear = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    navigate('/');
    dispatch(updateData({ currentRoute: "Select Service" ,isService: false,}));dispatch(clearData())
  }

  export const useErrorHandling = () => {
    const errorTrue = useStore((state)=>state.errorTrue)
    errorTrue()
  }


  export function getFlagImageUrl(flagCharacter) {
    const baseUrl = 'https://flagcdn.com/128x128/'; // You can change the base URL and size as needed
    return `${baseUrl}${flagCharacter}.png`;
  }