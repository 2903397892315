import React, { useEffect } from "react";
import Header from "../../includes/Header";
import { Route, Routes, useNavigate } from "react-router-dom";
import SelectService from "../../screens/applicationForm/SelectService";
import ApplicationForm from "../../screens/applicationForm/ApplicationForm";
import ApplicationStatus from "../../screens/applicationForm/ApplicationStatus";
import Profile from "../../screens/applicationForm/Profile";
import PaymentDetails from "../../screens/applicationForm/PaymentDetails";
import PageNotFound from "../../screens/applicationForm/includes/PageNotFound";
import { useDispatch, useSelector } from "react-redux";
import Authroute from "./AuthRoute";
import { clearData, updateData } from "../../Redux/Actions";
import { HandleClear } from "../../../utils/functions";
import ApplicationNotFound from "../../screens/applicationForm/includes/ApplicationNotfound";
import ApplicationCoverDetail from "../../screens/applicationForm/ApplicationCoverDetail";
import Aboutus from "../../screens/applicationForm/Aboutus";

const MainRouter = () => {
  const persenal = useSelector(state=>state.Login.userData.isPersonal)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // useEffect(() => {
  //   console.log(state);
  //   if (persenal == true){
  //     console.log("hello world --==");
  //   // Add the beforeunload event listener when the component mounts
  //     const beforeUnloadHandler = (e) => {
  //       e.returnValue = 'Are you sure you want to leave this page?';
  //       dispatch(updateData({ currentRoute: "Select Service" ,isService: false,}));
  //       dispatch(clearData())
  //     };

  //     window.addEventListener('beforeunload', beforeUnloadHandler);

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', beforeUnloadHandler);
  //   };
  // }
  // }, []);
  useEffect(() => {
    if (persenal === true) {
  
      // Check if the user has interacted with the page before
      const hasInteracted = localStorage.getItem("hasInteracted");
      const beforeUnloadHandler = (e) => {
        // e.returnValue = 'Are you sure you want to leave this page?';
        dispatch(updateData({ currentRoute: "Select Service", isService: false }));
        dispatch(clearData());
      };
      if (!hasInteracted) {
        // If not, add the beforeunload event listener and set the flag
        const beforeUnloadHandler = (e) => {
          // e.returnValue = 'Are you sure you want to leave this page?';
          dispatch(updateData({ currentRoute: "Select Service", isService: false }));
          dispatch(clearData());
        };
  
        window.addEventListener('beforeunload', beforeUnloadHandler);
  
        // Set the flag to indicate that the user has interacted
        localStorage.setItem("hasInteracted", "true");
      }
  
      // Remove the event listener when the component unmounts
      return () => {
        window.removeEventListener('beforeunload', beforeUnloadHandler);
      };
    }
  }, []);
  const state = useSelector(state=>state)
  return (
    <Routes>
      {/* <Route path="/" element={<Header/>}> */}
      <Route path="/" element={<ApplicationForm />} />

      <Route path="/profile/:id" element={<Authroute><ApplicationStatus /></Authroute>} />
      <Route path="/profile" element={<Authroute><Profile /></Authroute>} />
      <Route path="/application/:id" element={<Authroute><ApplicationCoverDetail /></Authroute>} />
      <Route path="/payment/:id" element={<Authroute><PaymentDetails /></Authroute>} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/profile/application-not-found" element={<ApplicationNotFound/>} />
      <Route path= "/aboutus" element = {<Aboutus/>}/>
      
      {/* </Route> */}
    </Routes>
  );
};

export default MainRouter;
